<template>
<div class="section-background__right">
  <img class="section-background__img"
   src="/images/background/right-bg.svg" alt="section-background" />
</div>
</template>

<style lang="scss" scoped>
.section-background {
  &__right {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__img {
    width: 100%;
    transform: translate3d(0, 10%, 0);
    animation: wiggle 15s infinite linear;
  }
}

@keyframes wiggle {
  0%, 100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0,-10%);
  }
}

</style>
