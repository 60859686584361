<template>
<div class="slider-control__buttons">
  <button class="slider-control left" @click="$emit('prev')">
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.83 5.00049L7.41 1.41049L6 0.000488281L0 6.00049L6 12.0005L7.41
            10.5905L3.83 7.00049H16V5.00049H3.83Z" fill="#D5D6D7" />
    </svg>
  </button>

  <router-link v-if="centerBtn.show" class="slider-control_center"
   :to="{name: centerBtn.centerBtnPath}">
    <slot></slot>
  </router-link>

  <button class="slider-control right" @click="$emit('next')">
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.17 7.00049L8.59 10.5905L10 12.0005L16 6.00049L10 0.000488281L8.59
          1.41049L12.17 5.00049H0V7.00049H12.17Z" fill="#D5D6D7" />
    </svg>
  </button>
</div>
</template>

<script>
export default {
  emits: ['prev', 'next'],
  props: {
    centerBtn: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
$light-gray: #D5D6D7;
$blue:#4DB6BC;

.slider-control {
  color: $light-gray;
  transition-duration: 150ms;
  font-family: 'montserratbold', sans-serif;
  background: transparent;
  border-radius: 30px;
  border: 1px solid $light-gray;
  cursor: pointer;
  text-transform: uppercase;
  position: absolute;
  padding: 16px 25px 14px;

  .category & {
    padding: 8px 25px 6px;

    @media (max-width: 1130px) {
      padding: 8px 15px 6px;
    }

     @media (max-width: 400px) {
      padding: 6px 11px 4px;
    }
  }

  &_center {
    padding: 15px 122px 13px;
    border: 1px solid #D5D6D7;
    border-radius: 30px;
    transition-duration: 150ms;
    margin: 0 20px 0 0;
    font-family: 'montserratbold', sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 22px;

    &:hover {
      color: #4DB6BC;
      border: 1px solid #4DB6BC;
    }

    @media (max-width: 720px) {
      padding: 15px 45px 13px;
    }

    @media (max-width: 350px) {
      padding: 15px 30px 13px;
      text-align: center;
    }
  }

  &:hover {
    color: $blue;
    border: 1px solid $blue;

    svg path {
      fill: $blue;
    }
  }

  .slider-cards__controls & {
    top: 35%;

    .category & {
      top: 16%;
      @media (max-width: 780px) {
        top: 9%;
      }
       @media (max-width: 400px) {
        top: 17%;
      }
    }

    .partners__cards & {
      top: 20%;
    }
  }

  &.left {
    display: block;
    left: 0;
    @media (max-width: 635px) {
      left: -10px;
    }
    @media (max-width: 400px) {
      left: -15px;
    }
  }

  &.right {
    display: block;
    right: 0;
    @media (max-width: 635px) {
      right: -10px;
    }
    @media (max-width: 400px) {
      right: -15px;
    }
  }
}

.slider-controls {
  &.slider-control__buttons {
    padding: 40px 10px;
    display: flex;
    align-items: center;
  }

  & .slider-control {
    position: initial;
    margin-right: 20px;

    &:last-child {
      margin-right: 0px;
    }

    &.left,
    &.right {
      display: block;
      position: relative;
      padding: 16px 25px 14px;

      @media (max-width: 1080px) {
        .main-news__content & {
          display: none;
        }
      }

      @media (max-width: 720px) {
        display: none;
      }
    }
  }
}
</style>
