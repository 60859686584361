<template>
<section class="videos">
  <left-background />

  <div class="main-container videos__container">
    <h1 class="title title--right">
      Видео
    </h1>

    <div class="videos__content">
      <video-list class="videos__main-page" />
    </div>

    <video-slider :interval="8000" />
  </div>
</section>
</template>

<script>
import LeftBackground from '@/components/shared/background/LeftBg.vue';
import VideoSlider from '@/components/shared/sliders/VideoSlider.vue';
import VideoList from '@/components/media/VideoList.vue';

export default {
  components: {
    LeftBackground,
    VideoSlider,
    VideoList,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
$white: #FFFFFF;
$dark-gray: #2B3138;
$light-gray: #D5D6D7;
$purple: #8293E8;
$yellow: #F8A81B;
$green: #27AE60;
$blue:#4DB6BC;

.videos {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: 665px) {
      justify-content: center;
    }
  }

  .videos__list &__item {
    width: 49%;
    overflow: hidden;
    position: relative;
    background: $dark-gray;
    margin-bottom: 20px;
    z-index: 20;
    transition: 0.5s;
    cursor: pointer;

    @media (max-width: 680px) {
      width: 100%;
    }

    @media (max-width: 500px) {
      &:last-child {
        margin-bottom: 0;
      }
    }

    &:hover {

      .videos__text,
      .videos__name {
        color: $white;
      }

      .videos__link-text {
        color: $dark-gray;
      }
    }
  }

  &__link {
    color: $white;
    display: flex;
    height: 100%;
    flex-direction: column;

    &-text {
      text-transform: uppercase;
      font-family: 'montserratbold', sans-serif;
      font-size: 12px;
      line-height: 20px;
      color: $purple;
    }
  }

  &__name {
    text-transform: uppercase;
    font-family: 'montserratbold', sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: $white;
    background: $blue;
    border-radius: 20px;
    padding: 5px 18px;
    margin-right: 10px;

    @media (max-width: 1252px) {
      width: 70%;
    }

    @media (max-width: 780px) {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
  }

  &__img {
    display: flex;
    width: 100%;
    height: 340px;
    border-radius: 20px;
    overflow: hidden;
    justify-content: center;

    &-pic {
      width: 100%;
      object-fit: cover;
    }

    @media (max-width: 1040px) {
      height: 235px;
    }

    @media (max-width: 770px) {
      height: 180px;
    }

    @media (max-width: 680px) {
      height: 265px;
    }

    @media (max-width: 425px) {
      height: 180px;
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $light-gray;
    padding: 30px 0 35px;
    transition: 0.5s;
  }

  &__details {
    display: flex;
    justify-content: space-between;
  }

  &__data {
    font-family: 'montserratbold', sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: $white;
  }
}
</style>
