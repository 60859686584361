<template>
<div class="banner">

  <main-slider :slides="slides" controls dots :page="page"
  :currentSlides="currentSlides" @showModal="$emit('showModal')"/>

</div>
</template>

<script>
import MainSlider from '@/components/shared/sliders/MainSlider.vue';

export default {
  emits: ['closeModal', 'showModal'],
  components: {
    MainSlider,
  },
  data() {
    return {
      currentSlides: 0,
      page: 'mainPage',
      slides: [
      // {
      //   title: {
      //     colortitle: 'Новогодняя',
      //     aftercolortitle: '<br>АКЦИЯ',
      //   },
        //   text: 'С декабря 2023 года по январь 2024 года компания Ctrl2GO проводит
        // Новогоднюю акцию на использование сервиса RiskAgro в 2024 году.',
      //   bannerMainPic: '/images/slider/noutbook.png',
      //   button: 'Подробнее',
      //   bannerBackground: '/svg/mainSlider/bg-left.svg',
      // },
      // {
      //   title: {
      //     colortitle: 'С Новым Годом!',
      //     aftercolortitle: '<br>2024',
      //   },
      //   text: 'Пусть все будет',
      //   bannerMainPic: '/images/slider/newyear-2024.svg',
      // },
        {
          title: 'CLASS – система поддержки принятия решений на основе полного и объективного анализа временных рядов пространственных данных',
          bannerMainPic: '/images/slider/main-slider2.png',
          text: 'Работая с большими данными, анализируем, обучаем и прогнозируем в целях повышения эффективности вашего бизнеса с заботой о природе и экологии!',
        },

        {
          title: {
            colortitle: 'Классификация',
            aftercolortitle: '<br>посевных площадей',
          },
          text: 'Посевные площади классифицируются по различным качественным признакам: биологическим особенностям культур, производственному назначению, учетным категориям и др.',
          url: 'crop-area',
          bannerMainPic: '/images/slider/main-slider3.png',
          button: 'Подробнее',
          bannerBackground: '/svg/mainSlider/bg-right.svg',
          bannerIcons: [{
            id: 1,
            path: '/svg/mainSlider/sputnik.svg',
          },
          {
            id: 2,
            path: '/svg/mainSlider/dron.svg',
          },
          {
            id: 3,
            path: '/svg/mainSlider/phone.svg',
          },
          ],
        },
        {
          title: {
            colortitle: 'Прогноз',
            aftercolortitle: '<br>урожайности',
          },
          text: 'Технология компании позволяет проводить оценку биологической продуктивности сельскохозяйственных культур с использованием имитационных моделей продуктивности, и моделей, основанных на методах машинного обучения с использованием метеоданных, различных видов данных дистанционного зондирования Земли и данных полевых экспериментов.',
          url: 'forecast',
          bannerMainPic: '/images/slider/main-slider4.png',
          button: 'Подробнее',
          bannerBackground: '/svg/mainSlider/bg-left.svg',
          bannerIcons: [{
            id: 1,
            path: '/svg/mainSlider/sputnik.svg',
          },
          {
            id: 2,
            path: '/svg/mainSlider/dron.svg',
          },
          {
            id: 3,
            path: '/svg/mainSlider/phone.svg',
          },
          ],
        },
        {
          title: {
            colortitle: 'Оценка',
            aftercolortitle: '<br>урожайности',
          },
          text: 'Наши технологии позволяют оценивать состояние посевов в любой момент времени. Получаемые оценки соответствуют требованию независимости от момента наступления страхового случая и подачи страхового иска.<br><br>Технология предусматривает одновременное использование данных дистанционного зондирования Земли с различных спутниковых и беспилотных систем.',
          url: 'yield-eval',
          bannerMainPic: '/images/slider/main-slider5.png',
          button: 'Подробнее',
          bannerBackground: '/svg/mainSlider/bg-right.svg',
          bannerIcons: [{
            id: 1,
            path: '/svg/mainSlider/sputnik.svg',
          },
          {
            id: 2,
            path: '/svg/mainSlider/dron.svg',
          },
          {
            id: 3,
            path: '/svg/mainSlider/phone.svg',
          },
          ],
        },
        {
          title: {
            colortitle: 'Оценка',
            aftercolortitle: ' ущерба',
          },
          text: 'Оценка ущерба при гибели и повреждении урожая сельскохозяйственных культур от стихийных бедствий или других неблагоприятных условий агропроизводства проводится как правило в целях возмещения ущерба исходя из условий агрострахования. ',
          url: 'loss-eval',
          bannerMainPic: '/images/slider/main-slider6.png',
          button: 'Подробнее',
          bannerBackground: '/svg/mainSlider/bg-left.svg',
          bannerIcons: [{
            id: 1,
            path: '/svg/mainSlider/sputnik.svg',
          },
          {
            id: 2,
            path: '/svg/mainSlider/dron.svg',
          },
          {
            id: 3,
            path: '/svg/mainSlider/phone.svg',
          },
          ],
        },
        {
          title: {
            colortitle: 'Оценка <br>и ранжирование',
            aftercolortitle: '<br>сельскохозяйственных земель',
          },
          text: 'Исследование земель на предмет сельскохозяйственного потенциала для использования под определенные виды сельскохозяйственной деятельности, для выращивания определенных культур, для прогнозирования урожайности исследуемой территории.',
          url: 'rank-agro',
          bannerMainPic: '/images/slider/main-slider7.png',
          button: 'Подробнее',
          bannerBackground: '/svg/mainSlider/bg-right.svg',
          bannerIcons: [{
            id: 1,
            path: '/svg/mainSlider/sputnik.svg',
          },
          {
            id: 2,
            path: '/svg/mainSlider/dron.svg',
          },
          ],
        },
        {
          title: {
            colortitle: 'Борьба',
            aftercolortitle: '<br>с инвазивными <br>видами растений',
          },
          text: 'Предварительный список инвазионных видов России составляет 730 видов. Общими для всех трех регионов России являются 24 инвазионных вида, для европейской части России и Сибири – 29 инвазионных видов, для европейской части России и Дальнего Востока – 5 видов, для Сибири и Дальнего Востока – 8 видов.',
          url: 'invasive-plants',
          bannerMainPic: '/images/slider/main-slider8.png',
          button: 'Подробнее',
          bannerBackground: '/svg/mainSlider/bg-left.svg',
          bannerIcons: [{
            id: 1,
            path: '/svg/mainSlider/sputnik.svg',
          },
          {
            id: 2,
            path: '/svg/mainSlider/dron.svg',
          },
          ],
        },
        {
          title: {
            colortitle: 'Мониторинг',
            aftercolortitle: '<br>тематических СМИ',
          },
          text: 'Самой крупной территорией, для которой проводятся наши исследования, является Республика Индия, система CLASS ежедневно проводит мониторинг индийских СМИ с целью выявления событий, влияющих на агропроизводство в области растениеводства.',
          url: 'obs-media',
          bannerMainPic: '/images/slider/main-slider9.png',
          button: 'Подробнее',
          bannerBackground: '/svg/mainSlider/bg-right.svg',
          bannerIcons: [{
            id: 1,
            path: '/svg/mainSlider/internet.svg',
          }],
        },
        {
          title: {
            colortitle: 'Мониторинг <br>и прогнозирование',
            aftercolortitle: '<br>метеоусловий',
          },
          text: 'Система CLASS предоставляет сервис по мониторингу и прогнозированию метеоусловий необходимых для моделирования развития, оценки и прогноза урожайности сельскохозяйственных культур, кроме сельского хозяйства данный сервис применяется и в других отраслях таких как лесное хозяйство и экология, а также в области страхования и финансов. ',
          url: 'obs-weather',
          bannerMainPic: '/images/slider/main-slider10.png',
          button: 'Подробнее',
          bannerBackground: '/svg/mainSlider/bg-left.svg',
          bannerIcons: [{
            id: 1,
            path: '/svg/mainSlider/wheather.svg',
          },
          {
            id: 2,
            path: '/svg/mainSlider/internet.svg',
          },
          ],
        },
        {
          title: {
            toptitle: 'АНАЛИЗИРУЕМ',
            bottomtitle: 'детально',
          },
          textLeft: 'ОБУЧАЕМ <br>глубоко',
          bannerMainPic: '/images/slider/main-slider1.png',
          textBottom: 'ПРОГНОЗИРУЕМ <br>точно',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  height: 955px;
  background: -webkit-gradient(linear, left top, left bottom,
      from(#2B2D38), color-stop(100%, rgba(196, 196, 196, 0)), to(rgba(196, 196, 196, 0)));
  background: -o-linear-gradient(top, #2B2D38 0%, rgba(196,
        196, 196, 0) 100%, rgba(196, 196, 196, 0) 100%);
  background: linear-gradient(180deg, #2B2D38 0%, rgba(196,
        196, 196, 0) 100%, rgba(196, 196, 196, 0) 100%);

  @media (max-width: 2600px) {
    height: 983px;
  }

  @media (max-width: 2048px) {
    height: 943px;
  }

  @media (max-width: 1920px) {
    height: 973px;
  }

  @media (max-width: 1600px) {
    height: 866px;
  }

  @media (max-width: 1440px) {
    height: 790px;
  }

  @media (max-width: 1280px) {
    height: 664px;
  }

  @media (max-width: 1200px) {
    height: 530px;
  }

  @media (max-width: 1024px) {
    height: 525px;
  }

  @media (max-width: 854px) {
    height: 420px;
  }

  @media (max-width: 645px) {
    height: 350px;
  }
}
</style>
