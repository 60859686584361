<template>
<li class="check-list__item">

  <div class="check-list__icon">
    <svg class="check-list__icon-pic" width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_479_47)">
        <path d="M6.99642 0.421908L3.28167 5.06253L1.92042 3.70128C1.70903 3.49034
             1.42249 3.372 1.12385 3.37232C0.825214 3.37264 0.538931 3.49158 0.327985
              3.70297C0.117038 3.91436 -0.0012924 4.2009 -0.000975929 4.49954C-0.000659462
               4.79818 0.118278 5.08446 0.329672 5.29541L2.57967 7.54541C2.7906
                7.7564 3.0767 7.87497 3.37505 7.87503H3.43692C3.59492 7.86646 3.74932
                 7.82465 3.89006 7.75234C4.0308 7.68004 4.1547 7.57885 4.25367
                  7.45541L8.75367 1.83041C8.84601 1.71502 8.91471 1.58258 8.95587
                   1.44065C8.99702 1.29871 9.00981 1.15006 8.99351 1.00318C8.97722
                    0.856306 8.93215 0.714076 8.86089 0.584614C8.78962 0.455152 8.69355
                     0.340993 8.57817 0.248657C8.46279 0.156322 8.33035 0.0876171
                      8.18841 0.0464651C8.04648 0.00531309 7.89783 -0.00748014 7.75095
                       0.00881654C7.60407 0.0251132 7.46184 0.070181 7.33238
                        0.141445C7.20292 0.21271 7.08876 0.308775 6.99642 0.424158V0.421908Z"
                         fill="#fff" />
      </g>
    </svg>
  </div>

  <div class="check-list__text">
    <div class="check-list__name" v-html="checkListItem.text"></div>

    <ul class="desc-list" v-show="checkListItem.subtext">
      <li class="desc-list__item"
        v-for="item in checkListItem.descList"
        :key="item">
          <div class="desc-list__disc">
          </div>
          <div class="desc-list__text">
            {{item.text}}
          </div>
      </li>
    </ul>
  </div>

</li>
</template>

<script>
export default {
  props: {
    checkListItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },

};
</script>

<style lang="scss" scoped>
$blue: #4DB6BC;
$orange: #F8A81B;
$violet:  #8293E8;
$white: #fff;
$sky-blue: #5598CF;
$green: #27AE60;
$coral: #FF7D75;

.check-list {
  &__item {
    display: flex;
    width: 47%;
    margin: 0 20px 30px -15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    .check-list__item_full-width & {
      width: 100%;
    }

    &:last-child {
      margin: 0 20px 0 -15px;

      .risc-agro & {
        margin: 0 20px 20px -15px;

        @media (max-width: 720px) {
          margin: 0 0 20px 0;
        }
      }
    }
    .check-list_simulation & {
      margin: 0 0 0 -15px;
    }

    @media (max-width: 860px) {
      width: 100%;
      margin: 0 0 20px -15px;
    }

    @media (max-width: 720px) {
        margin: 0 17px 20px 0;

        .scoring & {
          margin: 0 0 20px 0;
        }
        &:last-child {
          margin: 0 0 20px 0;
        }
    }

  }
  &__name {
    display: flex;
    flex-direction: column;
      .border_violet & {
        color: $violet;
        border-color: $violet;
      }
      .check-list_white & {
        font-family: "montserratbold", sans-serif;
        color: #fff;
      }
      .remote-uav__colums &,
      .technologyai__colums &,
      .class-mining__column_method & {
        display: initial;
      }
      .class-mining__column_blue & {
        color: #2B3138;
        font-family: "montserratregular", sans-serif;
      }
  }

  &__icon {
    margin-right: 10px;

    &-pic {
      .icon_disc & {
        background-color: #4DB6BC;
        border-radius: 50%;
      }
      & path {
        fill: $blue;

        .check-list_orange & {
          fill: $orange;
        }
        .check-list_white & {
          fill: #fff;
        }
        .check-list_violet & {
          fill: $violet;
        }
        .check-list_sky & {
          fill: $sky-blue;
        }
        .check-list_green & {
          fill: $green;
        }

        .check-list_coral & {
          fill: $coral;
        }
      }
    }
  }
}

.desc-list {
  &__item {
    margin-left: 20px;
    margin-bottom: 10px;
    display: flex;
  }

  &__disc {
    margin: 7px 10px 0 0;
    background-color: #D5D6D7;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    line-height: 22px;

    .check-list_coral & {
      background-color: $coral;
    }
    .check-list_blue & {
      background-color: $blue;
    }
  }

  &__text {
    width: 100%;
  }
}
</style>
