<template>
<header ref='header' class="header">
  <div class="main-container header__container">

    <div class="header__col">
      <header-logo />
    </div>

    <div class="header__col header__col-sidebar" :class="{ 'sidebar-open' : isOpen }">
      <header-menu
      :isOpen="isOpen"
      @closeModalMenu="closeModalMenu"/>
    </div>

    <div class="header__col">

      <header-language />

      <header-user />

      <header-hamburger @addClassOpen="toggleMobileMenu" :isOpen="isOpen"/>

    </div>
  </div>
</header>
</template>

<script>
import HeaderLanguage from '@/components/header/LanguageChange.vue';
import HeaderUser from '@/components/header/HeaderUser.vue';
import HeaderLogo from '@/components/header/HeaderLogo.vue';
import HeaderMenu from '@/components/header/HeaderMenu.vue';
import HeaderHamburger from '@/components/header/HeaderHamburger.vue';

export default {
  data() {
    return {
      isOpen: false,
      timerMenu: 0,
    };
  },
  methods: {
    toggleMobileMenu() {
      this.isOpen = !this.isOpen;
    },
    closeModalMenu() {
      this.isOpen = false;
    },
  },
  components: {
    HeaderLanguage,
    HeaderUser,
    HeaderMenu,
    HeaderHamburger,
    HeaderLogo,
  },
};
</script>

<style lang="scss" scoped>
$dark-gray:#2B3138;

.header {
  position: fixed;
  z-index: 100;
  background: $dark-gray;
  width: 100%;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 20%);

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;

    @media (max-width: 1415px) {
      padding: 20px 25px;
    }

    @media (max-width: 854px) {
      padding: 12px 25px;
    }

    @media (max-width: 420px) {
      padding: 10px;
    }
  }

  &__col {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

// Logo:
.logo__img {
  width: 196px;

  @media (max-width: 1400px) {
    width: 140px;
  }

  @media (max-width: 1180px) {
    width: 196px;
  }

  @media (max-width: 480px) {
    width: 120px;
  }
}

.logo__pic {
  width: 100%;
}

@media (max-width: 1180px) {
  .header__col-sidebar {
    width: 0;
    position: absolute;
    right: 0;
    top: 65px;
    background: $dark-gray;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: width .3s;
    height: calc(100vh - 68px);
    overflow: auto;
  }

  .sidebar-open {
    width: 370px;
    z-index: 90;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 20%);

    @media (max-width: 480px) {
      width: 100%;
    }
  }
}
</style>
