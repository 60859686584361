<template>
  <div class="logo">
    <router-link :to="{name: 'home'}" class="logo__link">
      <div class="logo__img">
        <img class="logo__pic" src="/svg/class_logo.svg" title="logo" alt="logo" />
      </div>
    </router-link>
  </div>

  </template>

  <style lang="scss" scoped>

  // Logo:
  .logo__img {
    width: 196px;

    @media (max-width: 1400px) {
      width: 140px;
    }

    @media (max-width: 1180px) {
      width: 196px;
    }

    @media (max-width: 480px) {
      width: 120px;
    }
  }

  .logo__pic {
    width: 100%;
  }

  </style>
