<template>
<div class="language">

  <div class="language__text">
    <router-link class="language__link" :to="{ name: selected.path }">
      <div class="language__icon">
        <img alt="language-icon" :src="selected.url" class="language__pic">
      </div>

      <p class="language__title"> {{selected.title}} </p>
    </router-link>
  </div>

  <div class="language__list">
    <div class="language__item"
     v-for="language, index in languages"
     :key="index" @click="selectLang(language)" @keydown="bar">
      <router-link class="language__link" :to="{ name: language.path }">

        <div class="language__icon">
          <img alt="language-icon" :src="language.url" class="language__pic">
        </div>

        <p class="language__title">{{language.title}}</p>
      </router-link>
    </div>
  </div>

</div>
</template>

<script>
export default {
  props: {
    languages: {
      type: Array,
      default() {
        return [];
      },
    },
    selected: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    // selectLang(language) {
    //   this.$emit('selectLanguage', language);
    // },
  },
};
</script>

<style lang="scss" scoped>
$white: #FFFFFF;
$gray: #808388;

.language {
  position: relative;
  z-index: 100;
  margin-right: 10px;
  width: 85px;

  @media (max-width: 385px) {
    margin-right: 0;
    width: 77px;
  }

  &__link {
    display: flex;
    align-items: flex-start;
  }

  &__title {
    color: $white;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;

    @media (max-width: 1113px) {
      font-size: 12px;
    }
  }

  &__item {
    padding: 5px 10px;
    &:last-child .language__link {
      opacity: .3;
      cursor: default;
      pointer-events: none;
    }

    &:hover {
      background: $gray;
    }

    @media (max-width: 420px) {
      padding: 7px;
    }
  }

  &__list {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    transition: 0.3s;
    position: absolute;
    right: -3px;
    top: 40px;
    background: #2B3138;
    border-radius: 10px;
    padding: 15px 0;

    @media (max-width: 1113px) {
      right: 7px;
    }

    @media (max-width: 420px) {
      right: 10px;
    }
  }

  &__text {
    color: $white;
    cursor: pointer;
    padding: 10px;
  }

  &:hover .language__list {
    display: flex;
  }

  &__icon {
    margin-right: 10px;
  }
}
</style>
