<template>
  <div class="default-layout">

    <header-component />

    <main class="main">
      <router-view />
    </main>

    <footer-component />

    <scroll-up :buttonShow="buttonShow"></scroll-up>

  </div>
  </template>

<script>
import { useRoute } from 'vue-router';
import HeaderComponent from '@/components/header/HeaderComponent.vue';
import FooterComponent from '@/components/footer/FooterComponent.vue';
import ScrollUp from '@/components/shared/ScrollUp.vue';

export default {
  emits: ['closeModal', 'showModal'],
  data() {
    return {
      buttonShow: false,
      showBanner: false,
      route: useRoute(),
    };
  },
  components: {
    HeaderComponent,
    FooterComponent,
    ScrollUp,
  },
  methods: {
    showModalWindow() {
      this.showBanner = true;
    },
  },
  computed: {
    // добавляем класс компоненту в зависимости от маршрута
    componentStyle() {
      return this.route.path === '/';
    },
  },
};
</script>

  <style lang="scss" scoped>
  .default-layout {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr auto;
    height: 100%;
    position: relative;
  }

  .main {
    margin: 86px 0 50px;
    @media (max-width: 854px) {
      margin: 72px 0 50px;
    }
    @media (max-width: 420px) {
      margin: 67px 0 50px;
    }
  }
  .promo-banner {
    width: 390px;
    position: fixed;
    cursor: pointer;
    z-index: 99;
    top: 350px;
    right: 135px;
    background-color: transparent;
    border: none;
    transition: 0.7s;

    &.promo-mainpage {
      @media (max-width: 2720px) {
        top: initial;
        bottom: 200px;
      }

      @media (max-width: 1980px) {
        top: initial;
        bottom: 100px;
        right: 90px;
        width: 320px;
      }

      @media (max-width: 1275px) {
        right: 25px;
        width: 260px;
      }

      @media (max-width: 1020px) {

        width: 240px;
      }

      @media (max-width: 720px) {
        right: 25px;
      }

      @media (max-width: 645px) {
        right: 25px;
        width: 260px;
      }

      @media (max-width: 500px) {
        right: 25px;
        width: 170px;
      }
    }

    @media (max-width: 1500px) {
      top: 245px;
    }

    @media (max-width: 1275px) {
      right: 25px;
      width: 320px;
      top: 273px;
    }

    @media (max-width: 720px) {
      right: 25px;
      width: 260px;
      top: 190px;
    }

    @media (max-width: 500px) {
      right: 25px;
      width: 170px;
      top: 105px;
    }

    &__pic {
      width: 100%;
    }
  }
  </style>
