<template>
  <section class="feedback">
    <div class="main-container feedback__container">
      <h1 class="title title--right" id="feedback">
        Обратная связь
      </h1>

      <feedback-form></feedback-form>
    </div>
  </section>
</template>

<script>
import FeedbackForm from '@/components/footer/FeedbackForm.vue';

export default {
  components: {
    FeedbackForm,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.feedback {
  display: flex;
  flex-direction: column;
}

@media (max-width: 500px) {
  .title {
    margin-left: 25px;
  }
}
</style>
