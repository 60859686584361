<template>
<transition name="modal">
  <div class="modal" v-if="showModal">
    <div class="modal__container">
      <div class="modal__content">
        <slot></slot>
      </div>

      <base-button class="base-button_border" @click="$emit('closeModal')">
      <div class="modal__icon">
        <img class="modal__icon-pic" alt="banner-icon" src="/svg/close-bg.svg">
      </div>
    </base-button>
    </div>
  </div>
</transition>
</template>

<script>
import BaseButton from '@/components/shared/BaseButton.vue';

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  components: {
    BaseButton,
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
  align-items: center;
  &__content {
    background: var(--grey-back, #2B3138);
    border: 1px solid #3E4751;
    border-radius: 8px;
    @media (max-width: 600px) {
      overflow: auto;
    }
  }
  &__container {
    max-width: 900px;
    margin: 0 auto;
    border-radius: 10px;
    position: relative;
    border: none;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.25);

    &.vertical {
      height: 100%;
    }

      @media (max-width: 1850px) {
        max-width: 755px;
        width: 100%;
      }

      @media (max-width: 835px) {
        width: 80%;
      }
      @media (max-width: 600px) {
        margin: 50px auto 20px;
        height: 91%;
        width: 87%;
      }
  }

  &__icon {
    width: 20px;

    &-pic {
      width: 100%;
      box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.25);
    }

    @media (max-width: 720px) {
      width: 30px;
    }
  }
}

.base-button_border {
  position: absolute;
  right: -44px;
  top: -22px;
  z-index: 100;
}

.modal-enter-active,
.modal-leave-active,
.modal-in-enter-active,
.modal-in-leave-active,
.modal-out-enter-active,
.modal-out-leave-active {
  transition: .3s;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
</style>
