<template>
  <div class="products_class">
    <products :products="productsClass" :productTitle="productTitleClass" :dubleItem="dubleItem" />
  </div>
</template>

<script>
import Products from '@/components/mainPage/MainProducts.vue';

export default {
  data() {
    return {
      dubleItem: true,
      productTitleClass: {
        text: 'CLASS.CLOUD',
        colorClass: 'purple',
      },
      productsClass: [{
        isLink: false,
        colorClass: 'purple',
        path: 'agro-metrika',
        picture: '/images/product/geo-class.jpg',
        title: 'GeoCLASS',
        text: 'Информационно-аналитический онлайн-сервис в области агрострахования, который позволяет страховым компаниям оценивать риски страхования урожая, сопровождать договора страхования, урегулировать убытки при наступлении страхового случая.',
      },
      {
        subProducts: [{
          isLink: true,
          colorClass: 'purple',
          path: 'geo-cadastre',
          picture: '/images/product/cadastre.jpg',
          title: 'GeoCLASS.<br>Сadastre',
        },
        {
          isLink: false,
          colorClass: 'purple',
          path: 'agro-metrika',
          picture: '/images/product/education.jpg',
          title: 'GeoCLASS.<br>Education',
        }],
      },
      {
        isLink: true,
        colorClass: 'purple',
        path: 'agro-metrika',
        picture: '/images/product/agrometric.jpg',
        title: 'AGROMETRIKA',
        text: 'Сервис «AGROMETRIKA» реализован на базе Системы CLASS, разработки компании Ctrl2GO, предназначен для автоматизации управления системой земледелия сельскохозяйственного предприятия и внутрихозяйственного учета земель сельскохозяйственного назначения.',
      },
      {
        isLink: true,
        colorClass: 'yellow',
        path: 'risc-agro',
        picture: '/images/product/risc-humans.jpg',
        title: 'RiskAgro',
        text: 'Информационно-аналитический онлайн-сервис в области агрострахования, который позволяет страховым компаниям оценивать риски страхования урожая, сопровождать договоры страхования, урегулировать убытки при наступлении страхового случая.',
      },

      {
        isLink: true,
        colorClass: 'yellow',
        path: 'agro-deposit',
        picture: '/images/product/deposit.jpg',
        title: 'AgroDeposit',
        text: 'Сервис предоставляет необходимый набор пространственных данных и удобные инструменты для удаленной работы с земельными участками в цифровом формате для обеспечения залоговой деятельности в банковской сфере при выдаче сельскохозяйственных кредитов.',
      },
      ],
    };
  },
  components: {
    Products,
  },
};
</script>

<style lang="scss" scoped>

:deep(.products__item:nth-child(2)) {
    box-shadow: none;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: initial;
  }

:deep(.products__item-subproducts .products__name br) {
  display: none;

  @media (max-width: 735px) {
    display: block;
  }

  @media (max-width: 660px) {
    display: none;
  }

  @media (max-width: 410px) {
    display: block;
  }
}
</style>
