<template>
<div class="header-user">

  <a class="header-user__link" target="_blank" rel="noopener"
   href="http://authapidev2.class-cloud.ru/login.page">

    <div class="header-user__icon">
      <svg class="header-user__pic" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.875 0C5.39325 0 3.375 2.01825 3.375 4.5V5.625C3.375 8.10675 5.39325
      10.125 7.875 10.125C10.3568 10.125 12.375 8.10675 12.375 5.625V4.5C12.375
        2.01825 10.3568 0 7.875 0Z" fill="#4DB6BC" />
        <path d="M12.4155 10.6042C11.2151 11.7 9.62438 12.375 7.875 12.375C6.12563
      12.375 4.53487 11.7 3.3345 10.6042C1.35337 11.25 0 12.7249
        0 14.625V18H15.75V14.625C15.75
        12.7249 14.3966 11.25 12.4155 10.6042Z" fill="#4DB6BC" />
      </svg>
    </div>

    <p class="header-user__title">Войти</p>

  </a>

</div>
</template>

<style lang="scss" scoped>
$white: #FFFFFF;
$blue: #4DB6BC;

.header-user {
  z-index: 100;

  &__title {
    color: $blue;
    font-family: 'montserratbold', sans-serif;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
  }

  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.3s;

    &:hover .header-user__title {
      color: $white;
      transition: 0.3s;
    }

    &:hover .header-user__pic path {
      fill: $white;
      transition: 0.3s;
    }
  }

  &__icon {
    margin-right: 10px;
  }
}
</style>
