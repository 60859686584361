<template>
<div class="videos__container">
  <ul class="videos__list">
    <li class="videos__item" v-for="(video, index) in videoList" :key="index" v-scrollanimation>
      <button class="videos__button" @click="showModalWindow(index)">
        <div class="videos__row">
          <div class="videos__img">
            <img class="videos__img-pic" alt="videos" :src="video.picture">
          </div>
          <video-icon></video-icon>
        </div>

        <div class="videos__row videos__row_desc">
          <div class="videos__details">
            <h3 class="videos__name">
              {{video.title}}
            </h3>
            <div class="videos__data">{{video.date}}</div>
          </div>
          <p class="videos__text">
            {{video.text}}
          </p>
        </div>
      </button>
    </li>
  </ul>

  <modal-mask class="videos__modal" :showModal="showModal" @closeModal="showModal = false">
    <iframe class="videos__iframe" :src="activeVideo" title="YouTube video player"
      allow="autoplay; fullscreen" allowfullscreen></iframe>
  </modal-mask>
</div>
</template>

<script>
import ModalMask from '@/components/shared/ModalMask.vue';
import VideoIcon from '@/components/shared/sliders/VideoIcon.vue';
import axios from 'axios';

export default {
  emits: ['closeModal'],
  components: {
    ModalMask,
    VideoIcon,
  },
  data() {
    return {
      showModal: false,

      activeVideo: 'https://www.youtube.com/embed/Sr5dmwhVeC4',

      videoList: [],
    };
  },
  mounted() {
    axios
      .get('/admin/api/v1/video')
      .then((response) => {
        const videoList = response.data.video;
        this.videoList = videoList;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      });
  },
  methods: {
    showModalWindow(index) {
      this.showModal = true;
      this.activeVideo = this.videoList[index].link;
    },
  },
};
</script>

<style lang="scss" scoped>
$white: #FFFFFF;
$dark-gray: #2B3138;
$light-gray: #D5D6D7;
$purple: #8293E8;
$yellow: #F8A81B;
$green: #27AE60;
$blue:#4DB6BC;

.videos {
  &__container {
    padding: 70px 180px;
    max-width: 70%;
    margin: 0 auto;

    @media (max-width: 2300px) {
      max-width: 100%;
    }

    &.videos__main-page {
      padding: 0;
    }

    @media (max-width: 1275px) {
      padding: 50px 50px;
    }

    @media (max-width: 480px) {
      padding: 40px 30px;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    align-items: baseline;

    @media (max-width: 665px) {
      justify-content: center;
    }
  }

  .videos__list &__item {
    width: 49%;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    z-index: 20;
    transition: 0.5s;
    cursor: pointer;

    .videos__main-page & {
      display: none;

      &:nth-child(-n+2) {
        display: block;
      }
    }

    @media (max-width: 680px) {
      width: 100%;
    }

    @media (max-width: 500px) {
      &:last-child {
        margin-bottom: 0;
      }
    }

    &:hover {

      .videos__text,
      .videos__name {
        color: $white;
      }

      .videos__link-text {
        color: $dark-gray;
      }
    }
  }

  &__name {
    text-transform: uppercase;
    font-family: 'montserratbold', sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: $white;
    background: $blue;
    border-radius: 20px;
    padding: 5px 18px;
    margin-right: 10px;
    text-align: initial;

    @media (max-width: 1252px) {
      width: 70%;
    }

    @media (max-width: 780px) {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
  }

  &__img {
    display: flex;
    width: 100%;
    height: 340px;
    border-radius: 20px;
    overflow: hidden;
    justify-content: center;

    &-pic {
      width: 100%;
      object-fit: cover;
    }

    @media (max-width: 1040px) {
      height: 235px;
    }

    @media (max-width: 770px) {
      height: 180px;
    }

    @media (max-width: 680px) {
      height: 265px;
    }

    @media (max-width: 425px) {
      height: 180px;
    }
  }

  &__row {
    position: relative;

    &_desc {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $light-gray;
    padding: 30px 0 35px;
    transition: 0.5s;
    text-align: initial;
  }

  &__details {
    display: flex;
    justify-content: space-between;
  }

  &__data {
    font-family: 'montserratbold', sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: $white;
  }

  &__button {
    position: relative;
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    transition: .5s;
    height: 100%;
    width: 100%;

  }

  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-width: 0;
    outline-width: 0;
  }
}

.before-enter {
  opacity: 0;
  transform: translateY(50px);
  transition: 4s ease;
}

.enter {
  opacity: 1;
  transform: translateY(0px);
}
</style>
