<template>
<div class="footer_copyright">
  «Ctrl2GO» Copyright © {{ currentYear }}
</div>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
.footer_copyright {
  color: #AAADAF;
  text-align: center;
  width: 100%;
  padding: 25px 0;
}
</style>
