<template>
<div class="main-page">

  <banner @showModal="showModalWindow()" />

  <section class="products">
    <div class="main-container products__container">
      <h1 class="title">
        Продукты
      </h1>

      <right-background />

      <ProductsCloud class="products__top" />

      <ProductsClass />
    </div>
  </section>

  <news />

  <industries />

  <video-component :mainPage="mainPage" />

  <statistics />

  <clients/>

  <partners/>

  <footer-form />

  <modal-mask-mini class="modal" :showModal="showModal" @closeModal="showModal = false">
    <NewYearModal></NewYearModal>
  </modal-mask-mini>

</div>
</template>

<script>
import Banner from '@/components/mainPage/MainBanner.vue';
import Statistics from '@/components/mainPage/MainStatistics.vue';
import Industries from '@/components/mainPage/MainIndustries.vue';
import ProductsClass from '@/components/mainPage/MainProductsClass.vue';
import ProductsCloud from '@/components/mainPage/MainProductsCloud.vue';
import News from '@/components/mainPage/MainNews.vue';
import VideoComponent from '@/components/mainPage/MainVideo.vue';
import FooterForm from '@/components/footer/FooterForm.vue';
import Clients from '@/components/mainPage/MainClients.vue';
import Partners from '@/components/mainPage/MainPartners.vue';
import ModalMaskMini from '@/components/shared/ModalMaskMini.vue';
import NewYearModal from '@/components/mainPage/NewYearModal.vue';
import RightBackground from '@/components/shared/background/RightBg.vue';

export default {
  emits: ['closeModal', 'showModal'],
  name: 'HomeView',
  data() {
    return {
      mainPage: true,
      showModal: false,
    };
  },
  components: {
    Statistics,
    Industries,
    ProductsClass,
    News,
    VideoComponent,
    Banner,
    FooterForm,
    Clients,
    Partners,
    ModalMaskMini,
    NewYearModal,
    RightBackground,
    ProductsCloud,
  },
  methods: {
    showModalWindow() {
      this.showModal = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.products__top {
  margin: 0 0 55px;
}
</style>
