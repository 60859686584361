<template>
<div class="banner__list" :class=" { 'mobile' : inMobile }">
  <template v-if="page === 'mainPage'">
    <all-slides-item v-for="(slide, index) in slides" :key="`item-${index}`"
     :slide="slide" :current-slide="currentSlide"
      :index="index" :direction="direction" :trans="trans" @showModal="$emit('showModal')"/>
  </template>

  <template v-else-if="page === 'gallery'">
    <gallery-slides v-for="(slide, index) in slides" :key="`item-${index}`"
     :slide="slide" :current-slide="currentSlide" :index="index" :direction="direction">
    </gallery-slides>
  </template>

  <div class="banner__buttons">
    <div class="slider-buttons">
      <slider-controls v-if="controls" @prev="prevSlide" @next="nextSlide" />
      <slider-dots v-if="dots" :total="slides.length"
       :current-index="currentSlide" @switch="switchSlide($event)" />
    </div>
  </div>
</div>
</template>

<script>
import AllSlidesItem from '@/components/shared/sliders/AllSlidesItem.vue';
import SliderControls from '@/components/shared/sliders/SliderControls.vue';
import SliderDots from '@/components/shared/sliders/SliderDots.vue';
import GallerySlides from '@/components/media/GallerySlides.vue';

export default {
  emits: ['closeModal', 'showModal'],
  props: {
    slides: {
      type: Array,
      required: true,
    },
    controls: {
      type: Boolean,
      default: false,
    },
    dots: {
      type: Boolean,
      default: false,
    },
    page: {
      type: String,
      required: true,
    },
    interval: {
      type: Number,
      default: 8000,
    },
    currentSlides: {
      type: Number,
      required: true,
    },
  },
  components: {
    AllSlidesItem,
    SliderControls,
    SliderDots,
    GallerySlides,
  },
  data() {
    return {
      trans: false,
      inMobile: false,
      slideInterval: null,
      currentSlide: this.currentSlides,
      slideIntervals: [8000, 8000, 8000, 8000, 8000, 8000, 8000, 8000, 8000, 8000],
      direction: 'right',
      window: {
        width: 0,
      },
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
    },
    setCurrentSlide(index) {
      this.currentSlide = index;
    },
    prevSlide(step = -1) {
      const index = this.currentSlide > 0 ? this.currentSlide + step : this.slides.length - 1;
      this.setCurrentSlide(index);
      this.direction = 'left';
      this.startSliderTimer();
    },
    next(step = 1) {
      const index = this.currentSlide < this.slides.length - 1 ? this.currentSlide + step : 0;
      this.setCurrentSlide(index);
      this.direction = 'right';
    },
    nextSlide(step = 1) {
      this.next(step);
      this.startSliderTimer();
      this.currentSlide %= this.slides.length;

      if (this.currentSlide === 0) {
        clearInterval(this.slideInterval);
        this.slideInterval = setInterval(this.nextSlide, this.slideIntervals[0]);
      } else {
        clearInterval(this.slideInterval);
        this.slideInterval = setInterval(this.nextSlide, this.slideIntervals[this.currentSlide]);
      }
    },
    startSliderTimer() {
      this.trans = true;
      this.inMobile = true;
      if (!/Android|webOS|iPhone|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i
        .test(navigator.userAgent) && this.window.width >= 720) {
        this.inMobile = false;
        this.stopSliderTimer();
        if (this.currentSlide === 0) {
          this.slideInterval = setInterval(this.nextSlide, this.slideIntervals[0]);
        } else {
          this.slideInterval = setInterval(this.nextSlide, this.slideIntervals[this.currentSlide]);
        }
      } else if (this.page === 'gallery') {
        this.inMobile = false;
        this.stopSliderTimer();
        this.slideInterval = setInterval(() => {
          this.next();
        }, this.interval);
      }
    },
    stopSliderTimer() {
      clearInterval(this.slideInterval);
    },
    switchSlide(index) {
      const step = index - this.currentSlide;
      if (step > 0) {
        this.nextSlide(step);
      } else {
        this.prevSlide(step);
      }
    },
  },
  mounted() {
    this.startSliderTimer();
  },
  beforeUnmount() {
    this.stopSliderTimer();
  },
};
</script>

<style lang="scss" scoped>
$white: #FFFFFF;
$blue:#4DB6BC;
$coral: #FF7D75;

.banner {
  &__list {
    position: absolute;
    z-index: 50;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  &__buttons {
    position: absolute;
    transform: translateX(-50%);
    bottom: 105px;
    left: 50%;
    max-width: 430px;
    width: 100%;
    z-index: 90;

    .gallery__modal & {
      bottom: 49px;
      max-width: 500px;

      @media (max-width: 720px) {
        display: block;
      }
    }

    .mobile & {
      display: none;
    }

    @media (max-width: 1200px) {
      bottom: 74px;
    }

    @media (max-width: 720px) {
      display: none;
    }
  }
}

.slider-buttons {
  position: relative;
}
</style>
