<template>
<div class="landing-layout">
  <router-view />

  <scroll-up :buttonShow="buttonShow"></scroll-up>
</div>
</template>

<script>
import ScrollUp from '@/components/shared/ScrollUp.vue';

export default {
  data() {
    return {
      buttonShow: false,
    };
  },
  components: {
    ScrollUp,
  },
};
</script>
