<template>
<footer ref='footer' class="footer">
  <div class="main-container footer__container">
    <div class="footer__row footer__row-gray">
      <h1 class="title footer__title">
        КОНТАКТЫ
      </h1>
      <div class="footer__info">
        <div class="footer__col">
          <footer-contacts />
        </div>

        <div class="footer__col footer__col-sidebar">
          <footer-menu />
        </div>

        <div class="footer__col">
          <footer-about />
        </div>
      </div>
    </div>
    <div class="footer__row footer__row-copyright">
      <footer-copyright />
    </div>
  </div>
</footer>
</template>

<script>

import FooterContacts from '@/components/footer/FooterContacts.vue';
import FooterMenu from '@/components/footer/FooterMenu.vue';
import FooterAbout from '@/components/footer/FooterAbout.vue';
import FooterCopyright from '@/components/footer/FooterCopyright.vue';

export default {
  data() {
    return {

    };
  },
  components: {
    FooterContacts,
    FooterMenu,
    FooterAbout,
    FooterCopyright,
  },
};
</script>

<style lang="scss" scoped>
$white: #FFFFFF;

a {
  text-decoration: none;
  color: inherit;
}

.footer {
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
  color: $white;
  margin: 0;
  z-index: 20;
  grid-row: end-line;

  &__container {
    padding: 0;
  }

  &__info {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1060px) {
      flex-wrap: wrap;
      justify-content: space-around;
    }

    @media (max-width: 750px) {
      justify-content: space-between;
    }
  }

  &__col {
    width: auto;

    @media (max-width: 1060px) {
      width: 50%;
    }

    @media (max-width: 605px) {
      width: 100%;
    }

    &-sidebar {
      @media (max-width: 1350px) {
        width: 25%;
      }

      @media (max-width: 1060px) {
        width: 30%;
      }

      @media (max-width: 605px) {
        width: 100%;
      }
    }

    &:first-child {
      @media (max-width: 750px) {
        width: 100%;
      }
    }
  }

  &__title {
    margin-bottom: 10px;
    font-size: 48px;
    color: $white;

    @media (max-width: 1000px) {
      font-size: 52px;
    }

    @media (max-width: 620px) {
      font-size: 32px;
    }
  }

  &__row {
    padding: 50px 155px;

    @media (max-width: 1250px) {
      padding: 50px 50px;
    }

    &-copyright {
      padding: 0 50px;
    }

    &-form {
      @media (max-width: 500px) {
        padding: 50px 25px 50px 0;
      }
    }

    &-gray {
      background: #555A60;

      @media (max-width: 500px) {
        padding: 50px 25px;
      }
    }
  }
}
</style>
