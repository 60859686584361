<template>
<div class="alternate-layout">
  <h2>Без хедер и футер</h2>

  <main class="main">
    <router-view />
  </main>
</div>
</template>

<style lang="scss" scoped>
h2 {
  font-size: 30px;
  padding: 30px 0;
  color: #42b983;
}
</style>
