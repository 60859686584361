import { createApp } from 'vue';
import YmapPlugin from 'vue-yandex-maps';
import App from './App.vue';
import router from './router';
import store from './store';
import ScrollAnimation from './directives/scrollanimation';

createApp(App).use(store).use(router).use(YmapPlugin)
  .directive('scrollanimation', ScrollAnimation)
  .mount('#app');
