<template>
<transition :name="transitionEffect">
  <div class="images-slide__item" v-show="currentSlide === index">
    <div class="images-slide__img">
      <img class="images-slide__img-pic" alt="gallery slide" :src="slide">
    </div>
  </div>
</transition>
</template>

<script>
export default {
  props: {
    slide: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    direction: {
      type: String,
      required: true,
    },
    currentSlide: {
      type: Number,
      required: true,
    },
  },
  computed: {
    transitionEffect() {
      return this.direction === 'right' ? 'slide-out' : 'slide-in';
    },
  },
};
</script>

<style lang="scss" scoped>
$white: #FFFFFF;
$blue:#4DB6BC;
$coral: #FF7D75;
$dark-gray: #2B3138;

.images-slide {
  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 0 80px;
    position: absolute;
    z-index: 10;
    height: 100%;
    justify-content: center;

    @media (max-width: 1115px) {
      padding: 20px 25px 65px;

      .risc-agro &,
      .crop-production & {
        padding: 20px 0 65px;
      }
    }

    @media (max-width: 800px) {
      padding: 20px 35px 65px;
    }

    @media (max-width: 720px) {
      padding: 20px 5px 65px;
    }
  }

  &__img {
    border-radius: 20px;
    overflow: hidden;
    max-width: 850px;
    height: 100%;
    width: 100%;

    .risc-agro &,
    .crop-production & {
      max-width: none;
      height: initial;
      border-radius: 5px;
      border: 2px solid #fff;
    }

    @media (max-width: 1120px) {
      max-width: 720px;
    }

    &-pic {
      width: 100%;
      object-fit: cover;
      display: block;
      height: 100%;
      margin: 0 auto;
      border-radius: 20px;

      .risc-agro &,
      .crop-production & {
        border-radius: 0;
      }

    }
  }
}

.slide-enter-active,
.slide-leave-active,
.slide-in-enter-active,
.slide-in-leave-active,
.slide-out-enter-active,
.slide-out-leave-active {
  transition: 1s;
}

.slide-in-enter-from {
  transform: translate(-100%);
}

.slide-in-leave-to {
  transform: translate(100%);
  opacity: 0;
}

.slide-out-enter-from {
  transform: translate(100%);
}

.slide-out-leave-to {
  transform: translate(-100%);
  opacity: 0;
}
</style>
