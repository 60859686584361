<template>
<nav class="navigation footer_navigation">
  <ul class="footer_navigation__list">
    <li class="footer_navigation__item" v-for="link, index in footerLinks" :key="link.path">
      <router-link class="footer_navigation__link"
       :class="{ 'active': activeLink === index }" @click="activeLink = index" :to="link.path">
        {{link.text}}
      </router-link>
    </li>
  </ul>
</nav>
</template>

<script>
export default {
  data() {
    return {
      activeLink: '',
      footerLinks: [{
        text: 'Главная',
        path: '/',
      },
      {
        text: 'О нас',
        path: '/about',
      },
      {
        text: 'Технологии',
        path: '/technology',
      },
      {
        text: 'Статистика',
        path: '/#statistic',
      },
      {
        text: 'Контакты',
        path: '/contacts',
      },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$white: #FFFFFF;
$light-gray-nav: #D5D6D7;

.footer_navigation {
  &__list {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 20px;

    @media (max-width: 750px) {
      margin: 20px 0 0 0;
    }

    @media (max-width: 605px) {
      width: 50%;
    }
  }

  &__link {
    color: $light-gray-nav;
    font-family: 'montserratbold', sans-serif;
    font-size: 12px;
    line-height: 20px;
    position: relative;
    transition: 0.3s;
    padding-right: 15px;

  }

  .router-link-active {
    color: $white;
  }

  &__item {
    margin-right: 20px;
    position: relative;
    cursor: pointer;

    &:hover .footer_navigation__link {
      color: $white;
    }

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 1185px) {
      margin-right: 5px;
    }
  }
}
</style>
