<template>
<div class="only-text">
  <div class="container only-text__container">
    <div class="only-text__text" v-html="onlyText">
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    onlyText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
$light-gray: #D5D6D7;
$blue: #4DB6BC;

.only-text {
  &__container {
    z-index: 2;
    margin: 0 auto 30px;

    .risc-agro & {
      padding: 0;
    }

    .sensing-space &, .class-agro__text &, .class-mining &, .risc-agro__tools_text &, .promo & {
      padding: 0;
    }
    .class-agro__text-blue & {
      margin: 0;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 22px;
    color: $light-gray;
    .only-text_blue & {
      color: $blue;
    }
    .class-mining__column_blue & {
      color: #2B3138;
    }
  }
}
</style>
