<template>
<div class="products_class">
  <products :products="productsClass" :productTitle="productTitleClass" />
</div>
</template>

<script>
import Products from '@/components/mainPage/MainProducts.vue';

export default {
  data() {
    return {
      productTitleClass: {
        text: 'CLASS',
        colorClass: 'blue',
      },
      productsClass: [{
        isLink: true,
        colorClass: 'blue',
        path: 'class',
        picture: '/images/product/agro.jpg',
        title: 'CLASS',
        text: 'Программная платформа для автоматизации деятельности участников рынка сельскохозяйственного страхования в области оценки состояния посевных площадей на различных этапах развития возделываемых культур, с целью оптимизации затрат и повышения эффективности при решении задач.',
      },
      {
        isLink: true,
        colorClass: 'blue',
        path: 'class-mobile',
        picture: '/images/product/mobile.jpg',
        title: 'СLASS.MOBILE',
        text: 'Мобильные приложения для автоматизированного сбора результатов проведения полевых экспериментов, сбора первичных данных по классификации сельскохозяйственных культур, а также сбора данных о состоянии полей, севооборота и посевов.',
      },
      {
        isLink: true,
        colorClass: 'blue',
        path: 'scoring',
        picture: '/images/product/scoring.jpg',
        title: 'AgroScoring',
        text: 'Аналитический онлайн-сервис в области сельскохозяйственного страхования и кредитования для проведения скоринговой оценки угодий заемщика на территории Индии. Сервис предоставляется по API.',
      },
        // {
        //   path: 'class-forest',
        //   picture: '/images/product/forestProd.jpg',
        //   title: 'CLASS.FOREST',
        //   text: 'Леса являются господствующей формой растительности на Земле.
        // Они считаются залогом существования биосферы и благополучия сопутствующих сред.',
        // },
        // {
        //   path: 'class-carbon',
        //   picture: '/images/product/carbon.jpg',
        //   title: 'CLASS.CARBON',
        //   text: 'Высокотехнологичный комплекс экологического контроля и измерения
        // углеродного баланса, позволяющий оценивать и регулярно мониторить секвестрационный
        // потенциал сельскохозяйственных земель и лесных территорий.',
        // },
      ],
    };
  },
  components: {
    Products,
  },
};
</script>
