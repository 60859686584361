<template>
<div class="wrapper">
  <component :is='layout'>
  </component>
</div>
</template>

<script>
import AlternateLayout from '@/layouts/AlternateLayout.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import LandingLayout from '@/layouts/LandingLayout.vue';

export default {
  computed: {
    layout() {
      return `${this.$route.meta.layout}-layout`;
    },
  },
  components: {
    AlternateLayout,
    DefaultLayout,
    LandingLayout,
  },
};
</script>

<style lang="scss">
$dark-gray:#2B3138;
$light-gray: #C5C5C5;
$gray: #454C52;

@font-face {
    font-family: 'montserratregular';
    src: url('/public/fonts/Montserrat-Regular.woff') format('woff');
    src: url('/public/fonts/Montserrat-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratbold';
    src: url('/public/fonts/Montserrat-Bold.woff') format('woff');
    src: url('/public/fonts/Montserrat-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

.main-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 68px;
  z-index: 30;

  @media (max-width: 1140px) {
    padding: 0 45px;
  }

  @media (max-width: 680px) {
    padding: 0 25px;
  }
}

.container {
  position: relative;
  z-index: 80;
  padding: 0 180px;
  margin: 0 auto;
  max-width: 1600px;

  .pro & {
    padding: 0 160px;
      @media (max-width: 1275px) {
      padding: 0 40px;
    }

    @media (max-width: 480px) {
      padding: 0 20px;
    }
  }

  @media (max-width: 1275px) {
    padding: 0 50px;
  }

  @media (max-width: 480px) {
    padding: 0 30px;
  }
}

body,
html {
  height: 100%;
  width: 100%;
}

body {
  background-color: $dark-gray;
}

*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  margin: 0;
  padding: 0;
}

ul>li {
  list-style: none;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #D5D6D7;
  height: 100%;
  width: 100%;
  position: relative;
  font-family: 'montserratregular', sans-serif;
  font-style: normal;
  letter-spacing: 0.1px;
}

.wrapper {
  min-height: 650px;
  position: relative;
  height: 100%;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #22282F;
  border-radius: 3px;

  &-thumb {
    background-color: $gray;
    border-radius: 3px;
    &:hover {
      border: none;
    }
  }
}

.title {
  font-family: 'montserratbold', sans-serif;
  font-size: 70px;
  line-height: 56px;
  color: #808388;
  text-transform: uppercase;
  margin: 0 0 62px;
  position: relative;

  @media (max-width: 1000px) {
    font-size: 52px;
    margin: 0 0 42px;
    line-height: 40px;
  }

  @media (max-width: 645px) {
    font-size: 32px;
    margin: 0 0 22px;
  }

  &-product {
    font-family: 'montserratbold', sans-serif;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    max-width: 940px;
    margin: 0 0 30px 0;

    @media (max-width: 620px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &--right {
    text-align: right;
  }
}

section {
  position: relative;
  padding: 70px 0 50px;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 2400px) {
    width: 100%;
  }

  @media (max-width: 1000px) {
    padding: 50px 0 40px;
  }

  @media (max-width: 720px) {
    padding: 35px 0;
  }
}

.text-blue {
  color: #4DB6BC;
}
</style>
