<template>
<section class="cards">
  <left-background />

  <div class="main-container cards__container">
    <h1 class="title title--right">
      Клиенты
    </h1>

    <slider-cards :cardsList="clientsList" :interval="8000" class="client__cards" />
  </div>
</section>
</template>

<script>
import LeftBackground from '@/components/shared/background/LeftBg.vue';
import SliderCards from '@/components/shared/sliders/SliderCards.vue';

export default {
  components: {
    LeftBackground,
    SliderCards,
  },
  data() {
    return {
      clientsList: [{
        picture: '/images/clients/client1.png',
      },
      {
        picture: '/images/clients/client2.png',
      },
      {
        picture: '/images/clients/client3.png',
      },
      {
        picture: '/images/clients/client4.png',
      },
      {
        picture: '/images/clients/client5.png',
      },
      {
        picture: '/images/clients/client6.png',
      },
      {
        picture: '/images/clients/client7.png',
      },
      {
        picture: '/images/clients/client8.png',
      },
      {
        picture: '/images/clients/client9.png',
      },
      {
        picture: '/images/clients/client10.png',
      },
      {
        picture: '/images/clients/client11.png',
      },
      {
        picture: '/images/clients/client12.png',
      },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.cards____container {
  padding: 0 60px;

  @media (max-width: 1140px) {
    padding: 0 40px;
  }

  @media (max-width: 680px) {
    padding: 0 25px;
  }
}
</style>
