<template>
  <transition :name="transitionEffect">
    <div class="banner__item" v-show="currentSlide === index">

      <template v-if="index === 0">
        <div class="banner__item-second">
          <div class="banner__img">
            <img class="banner__pic" :src="slide.bannerMainPic" alt="banner_slide">
          </div>
          <div class="banner__description">
            <div class="banner__icon">
              <img class="banner__pic" src="/svg/mainSlider/corner.svg" alt="banner_corner">
            </div>
            <div class="banner__title">
              {{slide.title}}
            </div>
            <div class="banner__text">
              {{slide.text}}
            </div>
          </div>
        </div>
      </template>

      <template v-else-if="index === 9">
        <div class="banner__item-first">
          <div class="banner__img">
            <img class="banner__pic" :src="slide.bannerMainPic" alt="banner_slide">
          </div>
          <div class="banner__desc">
            <div class="banner__title">{{slide.title.toptitle}}
            </div>
            <div class="banner__text">
              <div class="banner__text-left" v-html="slide.textLeft"></div>
              <div class="banner__title banner__text-right"> {{slide.title.bottomtitle}}</div>
            </div>
            <div class="banner__text-bottom" v-html="slide.textBottom"></div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="banner__item-services">
          <router-link class="banner__link" :to="{name: slide.url }">
            <div class="banner__img">
              <img class="banner__pic" :src="slide.bannerMainPic" alt="banner main picture">
            </div>
            <div class="banner__info">
              <div class="banner__info-left">
                <ul class="banner_icons__list">
                  <li class="banner_icon__item" v-for="icon in slide.bannerIcons" :key="icon.id">
                    <div class="banner_icon__img">
                      <img class="banner_icon__pic" :src="icon.path" alt="Banner Icons">
                    </div>
                  </li>
                </ul>
              </div>
              <div class="banner__info-right">
                <div class="banner__description" :class="{'trans' : trans}">
                  <h2 class="banner__title">
                    <span class="banner__title-color" v-html="slide.title.colortitle"></span>
                    <span v-html="slide.title.aftercolortitle"></span>
                  </h2>
                  <div class="banner__text" v-html="slide.text">
                  </div>
                </div>
                <button class="banner__button">
                  {{slide.button}}
                </button>
              </div>
            </div>
          </router-link>
          <div class="banner__background">
            <img class="banner__background-img"
             :src="slide.bannerBackground" alt="banner Background" />
          </div>
        </div>
      </template>
    </div>
  </transition>
  </template>

<script>
export default {
  emits: ['closeModal', 'showModal'],
  props: {
    slide: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    direction: {
      type: String,
      required: true,
    },
    currentSlide: {
      type: Number,
      required: true,
    },
    trans: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    transitionEffect() {
      return this.direction === 'right' ? 'slide-out' : 'slide-in';
    },
  },
};
</script>

  <style lang="scss" scoped>
  $white: #FFFFFF;
  $blue:#4DB6BC;
  $coral: #FF7D75;
  $dark-gray: #2B3138;

  .logo-link__image {
    margin: 0 0 20px;
    width: 200px;

    &-pic {
      width: 100%;
    }

    @media (max-width: 950px) {
      width: 140px;
      margin: 0;
    }

    @media (max-width: 720px) {
      margin: 0 auto;
    }
  }

  .promo__background {
    position: absolute;
    top: 3%;
    right: 20%;
    width: 500px;

    @media (max-width: 2200px) {
      right: 10%;
    }

    @media (max-width: 1450px) {
      right: 5%;
    }

    @media (max-width: 1450px) {
      width: 300px;
    }

    @media (max-width: 950px) {
      width: 250px;
    }

    @media (max-width: 720px) {
      width: 150px;
      z-index: 8;
    }

    @media (max-width: 430px) {
      width: 100px;
    }
  }

  .banner {
    &__item {
      width: 100%;
      height: 100%;
      z-index: 90;
      position: absolute;

      &-services {
        width: 100%;
        margin: 0 auto;
        z-index: 90;
        height: 100%;
        background: #2B3138;
        background: -webkit-gradient(linear, left top, left bottom,
            from(#2B2D38), color-stop(100%, rgba(196, 196, 196, 0)), to(rgba(196, 196, 196, 0)));
        background: -o-linear-gradient(top, #2B2D38 0%, rgba(196,
              196, 196, 0) 100%, rgba(196, 196, 196, 0) 100%);
        background: linear-gradient(180deg, #2B2D38 0%, rgba(196,
              196, 196, 0) 100%, rgba(196, 196, 196, 0) 100%);

          .banner__link.promo  {
            flex-direction: row;
            position: relative;

            @media (max-width: 720px) {
              justify-content: center;
              text-align: center;
              padding: 20px 20px 20px;
              }
            }
              .banner__item:nth-child(2n) & {
          .banner__link {
            flex-direction: row;
          }

          .banner__info {
            flex-direction: row-reverse;
          }

          .banner__img {
            text-align: initial;
          }

          .banner__info-left {
            padding-right: 0;
            padding-left: 0;
          }

          .banner__background {
            right: 15%;
            left: initial;
          }
        }

      }

      &:nth-child(2n) {
        @media (max-width: 720px) {
          display: none;
        }
      }

    }

    &__link {
      display: flex;
      align-items: center;
      padding: 40px 70px 120px;
      flex-direction: row-reverse;
      justify-content: space-evenly;
      position: relative;
      z-index: 10;
      height: 100%;

      @media (max-width: 1440px) {
        justify-content: space-between;
      }

      @media (max-width: 1200px) {
        padding: 20px 25px 65px;
      }

      @media (max-width: 800px) {
        padding: 20px 35px 65px;
      }
    }

    &__info {
      display: flex;
      position: relative;
      z-index: 10;

      &-left {
        padding-right: 40px;

        @media (max-width: 1200px) {
          padding-right: 20px;
        }

        @media (max-width: 854px) {
          display: none;
        }
      }

      &-right {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: space-between;
        margin-right: 20px;

        @media (max-width: 1200px) {
          justify-content: space-around;
        }

        @media (max-width: 1200px) {
          margin-right: 0;
        }
      }
    }

    &__img {
      width: 22%;
      text-align: end;
      position: relative;

      &.promo {
        width: 33%;
        height: 100%;
        display: flex;
        align-items: center;

        @media (max-width: 2335px) {
          width: 42%;
        }

        @media (max-width: 1850px) {
          width: 50%;
        }

        @media (max-width: 1440px) {
          width: 60%;
        }
        @media (max-width: 1350px) {
          width: 50%;
        }
        @media (max-width: 720px) {
          display: none;
        }
      }

      @media (max-width: 2950px) {
        width: 25%;
      }

      @media (max-width: 2650px) {
        width: 28%;
      }

      @media (max-width: 2050px) {
        width: 35%;
      }

      @media (max-width: 1920px) {
        width: 36%;
      }

      @media (max-width: 1700px) {
        width: 40%;
      }

      @media (max-width: 1600px) {
        width: 38%;
      }

      @media (max-width: 1270px) {
        margin-right: 20px;
      }

    }

    &__pic {
      width: 100%;
      object-fit: cover;

      &.promo {
        position: relative;
        z-index: 10;
        width: initial;
        @media (max-width: 1850px) {
        width: 100%;
      }
      }

      @media (max-width: 1200px) {
        width: 90%;
      }
    }

    &__description {
      margin: 0 auto;
      color: $white;
      max-width: 550px;
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      &.trans {
        opacity: 0;
        animation: ani 2s forwards;
        animation-delay: .3s;
      }

      @media (max-width: 800px) {
        max-width: 340px;
      }
    }

    &__icon {
      position: absolute;
      right: 0;
      top: -22px;
      width: 39px;
      height: 39px;
    }

    &__title {
      font-family: 'montserratbold', sans-serif;
      font-size: 40px;
      line-height: 46px;
      margin-bottom: 55px;

      @media (max-width: 1200px) {
        font-size: 33px;
        line-height: 35px;
      }

      @media (max-width: 1200px) {
        margin-bottom: 35px;
      }

      @media (max-width: 950px) {
        font-size: 24px;
        line-height: 26px;
      }

      @media (max-width: 854px) {
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 25px;
      }

      &-color {
        color: $blue;
      }
    }

    &__text {
      font-family: 'montserratbold', sans-serif;
      font-size: 18px;
      line-height: 22px;
      max-width: 550px;
      margin-bottom: 50px;

      &.promo {
        font-size: 14px;
        line-height: 22px;
        color: #AAADAF;

        @media (max-width: 1300px) {
          line-height: 17px;
        }

        @media (max-width: 720px) {
          font-size: 10px;
          line-height: 14px;
        }
      }

      @media (max-width: 2000px) {
        max-width: 440px;
      }

      @media (max-width: 1300px) {
        font-size: 15px;
        line-height: 18px;
      }

      @media (max-width: 1200px) {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 30px;
      }

      @media (max-width: 854px) {
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 25px;
      }
    }

    &_icon__item {
      background: $dark-gray;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      padding: 35px 10px;
      text-align: center;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 20px;
      }
    }

    &__button {
      background: linear-gradient(90deg, #5EBA9B -85.72%, #28B5BE 178.5%, #28B5BE 225.13%);
      border-radius: 30px;
      padding: 10px 35px;
      border: none;
      color: $white;
      text-transform: uppercase;
      font-family: 'montserratbold', sans-serif;
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;

      @media (max-width: 854px) {
        font-size: 12px;
        padding: 7px 30px;
      }

      @media (max-width: 720px) {
        margin: 0 auto;
      }

      &:hover {
        box-shadow: 0px 8px 12px rgb(77 182 188 / 20%);
      }
    }

    &__background {
      position: absolute;
      bottom: 90px;
      left: 25%;

      @media (max-width: 2615px) {
        left: 15%;
      }

      @media (max-width: 1615px) {
        left: initial;
      }

      @media (max-width: 720px) {
        display: none;
      }

      &-img {
        display: block;
        width: 100%;
        object-fit: cover;

        @media (max-width: 1200px) {
          width: 77%;
        }
      }
    }

    &__item-newyear {
      width: 100%;
      height: 100%;
      position: relative;
      .banner {
        &__background {
          display: none;
        }

        &__img {
          width: 48%;
          height: 100%;
          margin: 0 auto;

          @media (max-width: 2840px) {
            width: 50%;
          }

          @media (max-width: 2620px) {
            width: 65%;
          }

          @media (max-width: 2000px) {
            width: 79%;
          }
          @media (max-width: 1750px) {
            width: 85%;
          }
          @media (max-width: 1440px) {
            width: 78%;
          }
        }

        &__pic {
          width: 100%;
          object-fit: cover;
        }

        &__title {
          font-size: 40px;
          line-height: 115px;
          margin-bottom: 0;
          font-family: 'montserratbold', sans-serif;

          &-color {
            font-size: 132px;
            font-weight: 900;
          }
        }
      }
    }

    &__item-first {
      width: 100%;
      height: 100%;

      .banner {
        &__img {
          width: 100%;
          height: 100%;
        }

        &__pic {
          width: 100%;
          object-fit: cover;
          height: 100%;
        }

        &__desc {
          margin: 0 auto;
          position: absolute;
          top: 50%;
          left: 37%;
          transform: translate(-32%, -50%);
          color: $white;
          max-width: 1000px;
          width: 100%;
          display: flex;
          flex-direction: column;

          @media (max-width: 1200px) {
            max-width: 652px;
          }

          @media (max-width: 800px) {
            max-width: 546px;
          }

          @media (max-width: 645px) {
            max-width: 372px;
          }

          @media (max-width: 435px) {
            max-width: 330px;
          }
        }

        &__title {
          font-family: 'montserratbold', sans-serif;
          font-size: 96px;
          line-height: 72px;
          margin-bottom: 20px;

          @media (max-width: 1200px) {
            font-size: 66px;
            line-height: 52px;
          }

          @media (max-width: 800px) {
            font-size: 49px;
            line-height: 45px;
            margin-bottom: 10px;
          }

          @media (max-width: 645px) {
            font-size: 32px;
            line-height: 38px;
            margin-bottom: 0;
          }
        }

        &__text {
          display: flex;
          justify-content: right;
          margin-bottom: 20px;
          max-width: initial;
          text-align: end;

          @media (max-width: 645px) {
            margin-bottom: 5px;
          }

          &-left {
            font-weight: 400;
            font-size: 63px;
            line-height: 56px;
            padding: 40px 40px 0 0;
            position: relative;

            @media (max-width: 1200px) {
              font-size: 50px;
              line-height: 46px;
            }

            @media (max-width: 800px) {
              font-size: 36px;
              line-height: 30px;
            }

            @media (max-width: 645px) {
              font-size: 28px;
              line-height: 28px;
              padding: 26px 20px 0 0;
            }

            &:before {
              content: "";
              display: block;
              background: linear-gradient(90deg, #5EBA9B -85.72%, #28B5BE 178.5%, #28B5BE 225.13%);
              width: 467px;
              height: 8px;
              position: absolute;
              top: 17px;
              right: 44px;

              @media (max-width: 1200px) {
                width: 270px;
              }

              @media (max-width: 800px) {
                width: 258px;
              }

              @media (max-width: 645px) {
                width: 179px;
                right: 29px;
                height: 3px;
                top: 13px;
              }

              @media (max-width: 435px) {
                width: 141px;

              }
            }
          }

          &-right {
            position: relative;

            &:after {
              content: "";
              display: block;
              background: $coral;
              width: 467px;
              height: 17px;
              position: absolute;
              bottom: 10px;
              right: 23px;

              @media (max-width: 1200px) {
                width: 314px;
              }

              @media (max-width: 800px) {
                width: 227px;
              }

              @media (max-width: 645px) {
                bottom: 38px;
                right: 23px;
                width: 141px;
                height: 6px;
              }
            }
          }

          &-bottom {
            font-family: 'montserratbold', sans-serif;
            font-size: 48px;
            line-height: 45px;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;

            @media (max-width: 1200px) {
              font-size: 40px;
            }

            @media (max-width: 800px) {
              font-size: 25px;
              line-height: 28px;
            }

            @media (max-width: 800px) {
              font-size: 20px;
              line-height: 20px;
            }
          }
        }
      }
    }

    &__item-second {
      width: 100%;
      height: 100%;
      position: relative;

      .banner {
        &__img {
          width: 100%;
          height: 100%;
        }

        &__pic {
          width: 100%;
          object-fit: cover;
          height: 100%;

          @media (max-width: 460px) {
            width: 110%;
          }
        }

        &__description {
          margin: 0 auto;
          position: absolute;
          top: 60%;
          left: 20%;
          transform: translate(10%, -60%);
          color: $white;
          max-width: 550px;
          width: 100%;
          display: flex;
          flex-direction: column;

          @media (max-width: 1940px) {
            left: 10%;
          }

          @media (max-width: 1200px) {
            left: 5%;
            top: 50%;
          }

          @media (max-width: 925px) {
            left: 0;
            top: 40%;
            transform: translate(10%, -37%);
          }

          @media (max-width: 854px) {
            max-width: 455px;
          }

          @media (max-width: 720px) {
            max-width: 400px;
          }

          @media (max-width: 460px) {
            max-width: 300px;
          }

          @media (max-width: 270px) {
            max-width: 270px;
          }

        }

        &__title {
          font-family: 'montserratbold', sans-serif;
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 65px;

          @media (max-width: 1200px) {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 34px;
          }

          @media (max-width: 854px) {
            font-size: 20px;
            line-height: 22px;
            margin-bottom: 25px;
          }

          @media (max-width: 460px) {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 20px;
          }
        }

        &__text {
          font-family: 'montserratbold', sans-serif;
          font-size: 18px;
          line-height: 22px;
          max-width: 440px;

          @media (max-width: 925px) {
            font-size: 16px;
            line-height: 20px;
          }

          @media (max-width: 460px) {
            font-size: 14px;
            line-height: 18px;
          }

        }
      }
    }
  }

  .banner__item-services:nth-child(3) .banner__pic,
  .banner__item-services:nth-child(10) .banner__pic {
    width: 88%;

    @media (max-width: 1200px) {
      width: 82%;
    }
  }

  .slide-enter-active,
  .slide-leave-active,
  .slide-in-enter-active,
  .slide-in-leave-active,
  .slide-out-enter-active,
  .slide-out-leave-active {
    transition: 1s;
  }

  .slide-in-enter-from {
    transform: translate(-100%);
  }

  .slide-in-leave-to {
    transform: translate(100%);
  }

  .slide-out-enter-from {
    transform: translate(100%);
  }

  .slide-out-leave-to {
    transform: translate(-100%);
  }

  @keyframes ani {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
</style>
