<template>
<div class="section-background__left">
  <img class="section-background__img"
   src="/images/background/left-bg.svg" alt="section-background" />
</div>
</template>

<style lang="scss" scoped>
.section-background {
  &__left {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__img {
    width: 100%;
    transform: translate3d(0, -10%, 0);
    animation: wiggle 15s infinite linear;
  }
}

@keyframes wiggle {
  0%, 100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0,10%);
  }
}
</style>
