<template>
<h3 class="title-product description__title"
 v-show="descTitleShow">
 <slot></slot>
</h3>
</template>

<script>
export default {
  props: {
    descTitle: {
      type: String,
      required: true,
    },
    descTitleShow: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
$light-gray: #D5D6D7;
$orange: #F8A81B;
$blue: #4DB6BC;
$violet:  #8293E8;
$coral: #FF7D75;

.description {
  &__title {
    color: $orange;
    .description__title_blue & {
      color: $blue;
    }
    .description__title_violet & {
      color: $violet;
    }
    .description__title_coral & {
      color: $coral;
    }
    .description_white & {
      color: #fff;
    }
    .description__title_white & {
      text-transform: initial;
      color: $light-gray;
      font-size: 16px;
      line-height: 22px;
      font-family: "montserratregular", sans-serif;
    }
  }
}
</style>
