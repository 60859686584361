<template>
<div class="feedback__content">
  <div class="feedback__img">
    <img class="feedback__img-pic" alt="form-picture" src="/images/form-pic.png">
  </div>

  <div class="feedback__form">
    <form class="form" @submit.prevent="handleSubmit" @keydown="bar" novalidate>
      <div class="form__content">
        <div class="form__row">
          <h3 class="form__title">
            Поделитесь мнением о нашей работе или задайте
            нам любой интересующий вас вопрос в поле комментарий
          </h3>
        </div>

        <div class="form__row">
          <div v-show="errors.text.usertext" class="form__error">
            {{ this.errors.text.usertext }}
            <div class="triangle"></div>
          </div>
          <label class="form__label" for="username">
            <input class="form__input" :class="{'focus' : this.user.username}"
             ref="username" required v-model="user.username" type="text" />
            <div class="form__label-title">Имя</div>
          </label>
        </div>

        <div class="form__row">
          <div v-show="errors.text.lastnametext" class="form__error">
            {{ this.errors.text.lastnametext }}
            <div class="triangle"></div>
          </div>
          <label class="form__label" for="lastname">
            <input class="form__input" :class="{'focus' : this.user.lastname}"
             ref="lastname" required v-model="user.lastname" type="text" />
            <div class="form__label-title">Фамилия</div>
          </label>
        </div>

        <div class="form__row">
          <div v-show="errors.text.phonetext" class="form__error">
            {{ this.errors.text.phonetext }}
            <div class="triangle"></div>
          </div>
          <label class="form__label" for="phone">
            <input class="form__input" :class="{'focus' : this.user.phone}"
             ref="phone" required v-model="user.phone" type="tel" />
            <div class="form__label-title">Телефон</div>
          </label>
        </div>

        <div class="form__row">
          <div v-show="errors.text.emailtext" class="form__error">
            {{ this.errors.text.emailtext }}
            <div class="triangle"></div>
          </div>
          <label class="form__label" for="email">
            <input class="form__input" :class="{'focus' : this.user.email}"
             ref="email" required v-model="user.email" type="email" />
            <div class="form__label-title">Ваш&nbsp;email</div>
          </label>
        </div>

        <div class="form__row">
          <div v-show="errors.text.commenttext" class="form__error">
            {{ this.errors.text.commenttext }}
            <div class="triangle"></div>
          </div>
          <label class="form__label" for="comment">
            <textarea class="form__input form__input-textarea"
             :class="{'focus' : this.user.comment}" ref="comment" required v-model="user.comment" />
            <div class="form__label-title">Комментарий</div>
              </label>
            </div>

            <div class="form__row">
              <div class="form__note">
                Нажимая кнопку «Отправить», я даю свое согласие на обработку
                моих персональных данных, в соответствии с Федеральным законом
                  от 27.07.2006 года №152-ФЗ «О персональных данных», на условиях
                  и для целей, определенных в Согласии на обработку персональных данных*
              </div>
            </div>
          </div>

          <div class="form__buttons form__row-center">
            <input
              class="form__button"
              type="submit"
              name="sendMail"
              value="отправить"
              :disabled="isSubmitDisabled"
            />
          </div>
      </form>
  </div>
</div>
</template>

<script>
import axios from 'axios';

const regularTel = /^\d+$/;

export default {
  data() {
    return {
      isSubmitDisabled: false,
      user: {
        username: '',
        lastname: '',
        phone: '',
        email: '',
        comment: '',
      },
      errors: {
        text: [{
          usertext: '',
          lastnametext: '',
          phonetext: '',
          emailtext: '',
          commenttext: '',
        }],
      },
    };
  },
  methods: {
    checkForm() {
      this.errors.text.usertext = '';
      this.errors.text.lastnametext = '';
      this.errors.text.phonetext = '';
      this.errors.text.emailtext = '';
      this.errors.text.commenttext = '';
      const regTel = !!regularTel.test(this.user.phone);
      const isValidMail = this.user.email.includes('@') && this.user.email.includes('.');

      if (!this.user.username) {
        this.errors.text.usertext = 'Введите ваше имя';
      }

      if (!this.user.lastname) {
        this.errors.text.lastnametext = 'Введите вашу фамилию';
      }

      if (!this.user.phone) {
        this.errors.text.phonetext = 'Введите номер телефона';
      } else if (!regTel) {
        this.errors.text.phonetext = 'Некорректный номер';
      }

      if (!this.user.email) {
        this.errors.text.emailtext = 'Введите адрес почты';
      } else if (!isValidMail) {
        this.errors.text.emailtext = 'Некорректный адрес';
      }

      if (!this.user.comment) {
        this.errors.text.commenttext = 'Введите комментарий';
      }

      return !this.errors.text.usertext
    && !this.errors.text.lastnametext
    && !this.errors.text.phonetext
    && !this.errors.text.emailtext
    && !this.errors.text.commenttext;
    },

    handleSubmit() {
      if (this.checkForm() === false) return;

      this.isSubmitDisabled = true;

      try {
        const userData = {
          nameUser: `${this.user.lastname} ${this.user.username}`,
          comment: `Адрес почты: ${this.user.email} <br> Номер телефона: ${this.user.phone} <br> Комментарий:  ${this.user.comment}`,
        };

        // Адрес для тестовой проверки:
        // const response = axios.post('https://test2go.class-cloud.ru/sendnewtest.php', userData, {
        const response = axios.post('/sendnew.php', userData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('response', response);
      } catch (error) {
        console.dir(error);
      } finally {
        this.clearForm();
        this.isSubmitDisabled = false;
      }
    },
    clearForm() {
      this.user.username = '';
      this.user.lastname = '';
      this.user.phone = '';
      this.user.email = '';
      this.user.comment = '';
    },
  },
};
</script>

<style lang="scss" scoped>
$white: #FFFFFF;
$blue: #4DB6BC;
$dark-gray:#2B3138;
$light-gray:#AAADAF;
$red: #F05E5E;

.feedback {
  &__content {
    display: flex;
    justify-content: center;

    &.contacts__form {
      position: relative;
      justify-content: start;
      margin: 80px 20px 0 0;

      @media (max-width: 880px) {
        flex-direction: column;
      }

      @media (max-width: 545px) {
        margin: 80px 0 0;
      }
    }

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  &__img {
    max-width: 450px;
    margin-right: -50px;
    z-index: 10;

    .contacts__form & {
      position: absolute;
      bottom: -66px;
      width: 395px;
      right: -106px;

      @media (max-width: 1980px) {
        right: -150px;
      }

      @media (max-width: 1825px) {
        right: -190px;
      }

      @media (max-width: 1735px) {
        right: -210px;
      }

      @media (max-width: 1654px) {
        right: -239px;
      }

      @media (max-width: 1564px) {
        right: -300px;
      }

      @media (max-width: 1370px) {
        right: -280px;
      }

      @media (max-width: 1101px) {
        right: 231px;
        bottom: -112px;
      }

      @media (max-width: 1070px) {
        right: 195px;
      }

      @media (max-width: 1030px) {
        position: initial;
      }

      @media (max-width: 880px) {
        margin-left: -10px;
      }

      @media (max-width: 600px) {
        max-width: 280px;
      }
    }

    &-pic {
      width: 100%;
      border-radius: 20px;
      box-shadow: 0px 8px 12px rgb(77 182 188 / 20%);

      @media (max-width: 550px) {
        object-fit: cover;
        width: 165px;
        height: 165px;
      }
    }

    @media (max-width: 1100px) {
      max-width: 370px;
      margin-left: -10px;
    }

    @media (max-width: 800px) {
      max-width: 300px;
      margin-left: -10px;
    }

    @media (max-width: 720px) {
      max-width: 280px;
      margin-left: -10px;
    }

    @media (max-width: 500px) {
      margin-left: -15px;
    }

    @media (max-width: 360px) {
      margin-right: 0;
    }
  }

  &__form {
    padding-top: 40px;

    @media (max-width: 800px) {
      display: flex;
      justify-content: flex-end;
      padding-top: 0;
    }

    .contacts__form & {
      @media (max-width: 880px) {
        display: flex;
        justify-content: flex-end;
        padding-top: 0;
      }
    }
  }
}

/* form */
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 4px solid #4DB6BC;
  border-radius: 20px;
  box-shadow: 0px 8px 12px rgb(77 182 188 / 20%);
  padding: 35px 65px 35px 95px;
  width: 540px;
  background-color: #2B3138;

  .contacts__form & {
    padding: 35px 100px 40px 40px;

    @media (max-width: 1101px) {
      padding: 35px 80px 40px 40px;
      width: 500px;
    }

    @media (max-width: 1030px) {
      padding: 35px 65px 35px 95px;
    }

    @media (max-width: 880px) {
      padding: 155px 65px 35px;
      margin-top: -120px;
    }

    @media (max-width: 540px) {
      padding: 155px 30px 35px;
      width: 95%;
    }
  }

  &__content {
    width: 100%;
  }

  &__title {
    color: $blue;
    font-family: 'montserratbold', sans-serif;
    font-size: 18px;
    line-height: 22px;
    max-width: 332px;
    margin: 0 0 20px;
  }

  &__note {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: $light-gray;
    padding: 5px 0 30px;
  }

  &__submit-error {
    color: $red;
    position: absolute;
    top: -30px;
    text-align: center;
  }

  &__row {
    display: flex;
    position: relative;

    &-center {
      justify-content: space-between;
      width: 100%;
    }
  }

  &__label {
    position: relative;
    width: 100%;
    padding: 2px 0;

    &-title {
      position: absolute;
      pointer-events: none;
      top: 50%;
      z-index: 1;
      transform: translateY(-50%);
      transition: all linear .3s;
      font-size: 14px;
      line-height: 22px;
      color: $white;
      left: 0;
    }
  }

  /* input */
  &__input {
    width: 100%;
    border-color: transparent;
    border-bottom: 1px solid $blue;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $white;
    background: $dark-gray;
    padding: 12px 0 0px;

    &:focus~.form__label-title,
    &.focus~.form__label-title {
      top: 23%;
      font-size: 12px;
      left: 100%;
      transform: translate(-100%, -50%);
      color: $light-gray;
    }

    &:focus-visible {
      outline: none;
    }

    &-textarea {
      height: 71px;
      padding: 20px 0 5px;

      &~.form__label-title {
        top: 20%;
      }
    }
  }

  ::-webkit-resizer {
    display: none;
  }

  &__error {
    margin-bottom: 10px;
    padding: 7px;
    font-size: 12px;
    color: #ffffff;
    background: $red;
    border-radius: 3px;
    text-align: center;
    transition: 0.5s;
    width: 200px;
    border: none;
    outline: 0;
    position: absolute;
    top: -20px;
    left: 22%;
    z-index: 3;
  }

  /* bottom */
  &__button {
    color: $white;
    transition-duration: 150ms;
    font-family: 'montserratbold', sans-serif;
    font-size: 14px;
    background: linear-gradient(90deg, #5EBA9B -85.72%, #28B5BE 178.5%, #28B5BE 225.13%);
    border-radius: 30px;
    border-width: 1px;
    border-color: transparent;
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
    padding: 11px 0;
  }

  &__button:hover {
    box-shadow: 0px 8px 12px rgb(77 182 188 / 20%);
  }

  @media (max-width: 800px) {
    padding: 155px 65px 35px;
    margin-top: -120px;
  }

  @media (max-width: 500px) {
    padding: 155px 25px 35px 45px;
    margin-left: -13px;
  }
}

.triangle {
  border-top: 10px solid $red;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  bottom: 90%;
  position: absolute;
  left: 50%;
  top: 90%;
}
</style>
