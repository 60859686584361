<template>
<button class="base-button">
  <slot></slot>
</button>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped>
$white: #fff;
$purple: #8293E8;
$blue: #4DB6BC;

.base-button {
  color: $purple;
  transition-duration: 150ms;
  font-family: 'montserratbold', sans-serif;
  font-size: 14px;
  line-height: 20px;
  background: transparent;
  border-radius: 30px;
  border: 1px solid $purple;
  padding: 14px 35px;
  cursor: pointer;
  text-transform: uppercase;

  &-center {
    padding: 13px 122px;

    @media (max-width: 645px) {
      margin-right: 0;
    }

    @media (max-width: 645px) {
      padding: 13px 45px;
    }

    @media (max-width: 350px) {
      padding: 13px 30px;
    }
  }

  &:hover {
    color: $white;
    border: 1px solid $purple;
    background: $purple;
  }

  &_blue {
    color: $blue;
    border: 1px solid $blue;

    &:hover {
      color: $white;
      border: 1px solid $blue;
      background: $blue;
    }
  }

  &_blue-bg {
    color: $white;
    border: 1px solid $blue;
    background: $blue;
    width: 100%;

    &:hover {
      box-shadow: 0px 8px 12px rgb(77 182 188 / 20%);
      color: $white;
      border: 1px solid $blue;
      background: $blue;
    }
  }

  &_border {
    border: none;

    &:hover {
      border: none;
      background: transparent;
    }
  }
}
</style>
