<template>
<section class="partners">
  <right-background />

  <div class="main-container partners__container">
    <h1 class="title title--left">
      Партнёры
    </h1>

    <slider-cards :cardsList="partnersList" class="partners__cards" :interval="8000">
    </slider-cards>
  </div>
</section>
</template>

<script>
import RightBackground from '@/components/shared/background/RightBg.vue';
import SliderCards from '@/components/shared/sliders/SliderCards.vue';

export default {
  components: {
    RightBackground,
    SliderCards,
  },
  data() {
    return {
      partnersList: [{
        picture: '/images/partners/partners1.png',
        name: 'Агрофизический научно-исследовательский институт, г. Санкт-Петербург',
        link: 'www.agrophys.ru',
      },
      {
        picture: '/images/partners/partners2.png',
        name: 'МГТУ им. Н.Э. Баумана',
        link: 'www.bmstu.ru',
      },
      {
        picture: '/images/partners/partners3.png',
        name: 'ИПУ РАН Институт проблем управления им. В.А.Трапезникова Российской Академии Наук',
        link: 'www.ipu.ru',
      },
      // {
      //   picture: '/images/partners/partners4.png',
      //   name: 'Agro-Soft',
      //   link: 'agro-soft.ru/wppage/agro-soft',
      // },
      {
        picture: '/images/partners/partners5.png',
        name: 'ФИЦ Почвенный институт им. В.В. Докучаева',
        link: 'www.esoil.ru',
      },
      {
        picture: '/images/partners/partners6.png',
        name: 'ААНИИ Арктический и антарктический научно-исследовательский институт, г. Санкт-Петербург',
        link: 'www.aari.ru',
      },
      {
        picture: '/images/partners/partners7.png',
        name: 'GEOSCAN',
        link: 'www.geoscan.aero/ru',
      },
      {
        picture: '/images/partners/partners8.png',
        name: 'РГАУ-МСХА им. К.А. Тимирязева',
        link: 'www.timacad.ru',
      },
      {
        picture: '/images/partners/partners9.png',
        name: 'TRINIТY GROUP Индия',
        link: 'trinityinsurance.co.in',
      },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.partners__container {
  padding: 0 60px;

  @media (max-width: 1140px) {
    padding: 0 40px;
  }

  @media (max-width: 680px) {
    padding: 0 25px;
  }
}
</style>
