<template>
<div class="videos__icon">
  <img class="videos__icon-pic" alt="videos_play_button" src="/svg/play.svg">
</div>
</template>

<script>
</script>

<style lang="scss" scoped>
$blue:#4DB6BC;

.videos {
  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70px;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba($blue, .5);
    -webkit-animation: pulse 2s infinite;
    animation: pulse 2s infinite;
    border-radius: 50%;

    &.videos-slider__icon {
      width: 55px;
    }

    &-pic {
      width: 100%;
      object-fit: cover;
      display: block;
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba($blue, .5);
    box-shadow: 0 0 0 0 rgba($blue, .5);
  }

  70% {
    -webkit-box-shadow: 0 0 0 15px rgba($blue, 0);
    box-shadow: 0 0 0 15px rgba($blue, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba($blue, 0);
    box-shadow: 0 0 0 0 rgba($blue, 0);
  }
}
</style>
