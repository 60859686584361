<template>
<ul class="slider-dots__list">
  <li class="slider-dots__item" :class="{active: currentIndex === index}"
   @click="$emit('switch', index)" @keypress="bar" v-for="(item, index) in total" :key="index">
    <button class="slider-dots__btn"></button>
  </li>
</ul>
</template>

<script>
export default {
  emits: ['switch'],
  props: {
    total: {
      type: Number,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
$light-gray: #D5D6D7;
$blue:#4DB6BC;

.slider-dots__list {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  z-index: 2;
  top: 7px;
  display: flex;
}

.slider-dots__item {
  cursor: pointer;
  padding: 5px 10px;

  &.active .slider-dots__btn,
  &:hover .slider-dots__btn {
    background: $blue;
  }
}

.slider-dots__btn {
  border: none;
  background: $light-gray;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  transition-duration: 150ms;
  cursor: pointer;
}
</style>
