<template>
<div class="slider-cards">
  <div class="slider-cards__container">
    <transition-group class="slider-cards__list" tag="ul" name="cards"
     @before-enter="beforeCb" @after-enter="afterCb">
      <cards-items v-for="cardsItem in slisedCards" :key="cardsItem" :cardsItem="cardsItem" />
    </transition-group>

    <slider-controls class="slider-cards__controls" @prev="slide('prev')"
     @next="slide('next')" :disabled="disabled" />
  </div>
</div>
</template>

<script>
import CardsItems from '@/components/shared/sliders/CardsItems.vue';
import SliderControls from '@/components/shared/sliders/SliderControls.vue';

export default {
  props: {
    cardsList: {
      type: Array,
      required: true,
    },
    interval: {
      type: Number,
      default: 0,
    },
  },
  components: {
    CardsItems,
    SliderControls,
  },
  data() {
    return {
      List: this.cardsList,
      window: {
        width: 0,
      },
      disabled: false,

    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    slisedCards() {
      if (this.window.width <= 525) {
        return [...this.List].splice(0, 1);
      }
      if (this.window.width <= 980) {
        return [...this.List].splice(0, 2);
      }
      if (this.window.width <= 1170) {
        return [...this.List].splice(0, 3);
      }
      if (this.window.width <= 1390) {
        return [...this.List].splice(0, 4);
      }
      if (this.window.width <= 1615) {
        return [...this.List].splice(0, 5);
      }
      if (this.window.width <= 1840) {
        return [...this.List].splice(0, 6);
      }
      return [...this.List].splice(0, 7);
    },
  },
  methods: {
    beforeCb() {
      this.disabled = true;
    },
    handleResize() {
      this.window.width = window.innerWidth;
    },
    slide(direction) {
      const lastItem = this.List[this.List.length - 1];
      switch (direction) {
        case 'next':
          this.List.push(this.List[0]);
          this.List.shift();
          break;
        case 'prev':
          this.List.unshift(lastItem);
          this.List.pop();
          break;
        default:
      }
    },
    afterCb() {
      this.disabled = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$white: #FFFFFF;
$dark-gray: #2B3138;
$light-gray: #D5D6D7;
$purple: #8293E8;
$yellow: #F8A81B;
$green: #27AE60;
$blue:#4DB6BC;

.slider-cards {
  position: relative;
  z-index: 80;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 40px 180px 0;
    position: relative;
    z-index: 20;

    @media (max-width: 1275px) {
      padding: 40px 50px 0;
    }

    @media (max-width: 490px) {
      padding: 0 30px;
    }

    .main-page & {
      padding: 0;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    width: 85%;
    height: 100%;

    @media (max-width: 890px) {
      width: 80%;
    }

    @media (max-width: 570px) {
      width: 75%;
    }

    @media (max-width: 525px) {
      justify-content: center;
    }
  }

}

.cards-move,
.cards-enter-active,
.cards-leave-active {
  transition: all .3s ease-out;
}

.cards-enter-to,
.cards-leave-to {
  opacity: 1;
}

.cards-enter-from,
.cards-leave-from {
  opacity: 0;
  transform: translateX(30px);
}

.cards-leave-active {
  position: absolute;
  transform: translateY(30px);
  opacity: 0;
}
</style>
