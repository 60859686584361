<template>
  <language-select :languages="languages"
   :selected="selected" @selectLanguage="selectLangItem" />
</template>

<script>
import LanguageSelect from '@/components/header/LanguageSelect.vue';

export default {
  data() {
    return {
      languages: [{
        title: 'RU',
        path: 'home',
        url: '/svg/lang/icon-ru.svg',
      },
      {
        title: 'EN',
        path: 'home',
        url: '/svg/lang/icon-en.svg',
      },
        // {
        //   title: 'HI',
        //   path: '/',
        //   url: '/svg/lang/icon-hi.svg',
        // },
      ],
      selected: {
        title: 'RU',
        path: 'home',
        url: '/svg/lang/icon-ru.svg',
      },
    };
  },
  components: {
    LanguageSelect,
  },
  methods: {
    selectLangItem(language) {
      this.selected = language;
    },
  },
};
</script>
