<template>
<div class="videos-slider">
  <div class="videos-slider__container">
    <transition-group class="videos-slider__list" tag="ul" name="list"
     @before-enter="beforeCb" @after-enter="afterCb">
      <video-slider-items @click="showModalWindow(index)"
       v-for="(videoSliderItem, index) in slisedSlides" :key="videoSliderItem"
        :videoSliderItem="videoSliderItem">
      </video-slider-items>
    </transition-group>

    <slider-controls class="slider-controls" :centerBtn="centerBtn"
     @prev="slide('prev')" @next="slide('next')" :disabled="disabled">
      смотреть все видео
    </slider-controls>
  </div>

  <modal-mask class="videos__modal" :showModal="showModal" @closeModal="showModal = false">
    <iframe class="videos-slider__iframe" :src="activeVideo"
     title="YouTube video player" allow="autoplay; fullscreen"
      allowfullscreen>
    </iframe>
  </modal-mask>
</div>
</template>

<script>
import VideoSliderItems from '@/components/shared/sliders/VideoSliderItems.vue';
import SliderControls from '@/components/shared/sliders/SliderControls.vue';
import ModalMask from '@/components/shared/ModalMask.vue';
import axios from 'axios';

export default {
  emits: ['closeModal'],
  props: {
    mainPage: {
      type: Boolean,
      default: true,
    },
    interval: {
      type: Number,
      default: 0,
    },
  },
  components: {
    VideoSliderItems,
    SliderControls,
    ModalMask,
  },
  data() {
    return {
      List: [],
      window: {
        width: 0,
      },
      disabled: false,
      showModal: false,
      centerBtn: {
        show: true,
        centerBtnPath: 'video',
      },

      activeVideo: 'https://www.youtube.com/embed/Sr5dmwhVeC4',

    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    slisedSlides() {
      if (this.window.width <= 720) {
        return [...this.List].splice(0, 4);
      }
      if (this.window.width <= 900) {
        return [...this.List].splice(0, 3);
      }
      if (this.mainPage) {
        return [...this.List].splice(0, 4);
      }
      if (this.mainPage && this.window.width <= 900) {
        return [...this.List].splice(0, 3);
      }
      return [...this.List].splice(0, 3);
    },
  },
  methods: {
    showModalWindow(index) {
      this.showModal = true;
      this.activeVideo = this.slisedSlides[index].link;
    },
    beforeCb() {
      this.disabled = true;
    },
    handleResize() {
      this.window.width = window.innerWidth;
    },
    slide(direction) {
      const lastItem = this.List[this.List.length - 1];
      switch (direction) {
        case 'next':
          this.List.push(this.List[0]);
          this.List.shift();
          break;
        case 'prev':
          this.List.unshift(lastItem);
          this.List.pop();
          break;
        default:
      }
    },
    afterCb() {
      this.disabled = false;
    },
  },
  mounted() {
    axios
      .get('/admin/api/v1/video')
      .then((response) => {
        const List = response.data.video;
        this.List = List;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      });
    if (this.window.width >= 720) {
      if (this.interval > 0) {
        const vm = this;
        setInterval(() => {
          vm.slide('next');
        }, vm.interval);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
$white: #FFFFFF;
$dark-gray: #2B3138;
$light-gray: #D5D6D7;
$purple: #8293E8;
$yellow: #F8A81B;
$green: #27AE60;
$blue:#4DB6BC;

.videos-slider {
  position: relative;
  z-index: 80;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 40px 180px 0;
    position: relative;
    z-index: 20;

    @media (max-width: 1275px) {
      padding: 40PX 50px 0;
    }

    @media (max-width: 490px) {
      padding: 0 30px;
    }

    .main-page & {
      padding: 0;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    @media (max-width: 720px) {
      flex-wrap: wrap;
    }
  }

  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-width: 0;
    outline-width: 0;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70px;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba($blue, .5);
    -webkit-animation: pulse 2s infinite;
    animation: pulse 2s infinite;
    border-radius: 50%;

    &-pic {
      width: 100%;
      object-fit: cover;
      display: block;
    }
  }
}

.list-move,
.list-enter-active,
.list-leave-active {
  transition: all .5s ease-out;
}

.list-enter-to,
.list-leave-to {
  opacity: 1;
}

.list-enter-from,
.list-leave-from {
  opacity: 0;
  transform: translateX(30px);
}

.list-leave-active {
  position: absolute;
  transform: translateY(30px);
  opacity: 0;
}
</style>
